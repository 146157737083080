var Comments = function (par) {
	this.container = par.container;
	this.type = par.type;
	this.related_to = par.related_to;
	this.maxlimit = 200;
	this.page = 1;
	this.lastPage = false;
	this.perPage = typeof par.perPage !== 'undefined' ? par.perPage : 5;
	Lang.setLocale(Opa.lang);
	this.draw();
};

Comments.instance = null;
Comments.prototype.container = null;
Comments.prototype.related_to = null;
Comments.prototype.type = null;
Comments.prototype.node = null;
Comments.prototype.form = null;
Comments.prototype.errorMsg = null;
Comments.prototype.maxlimit = null;
Comments.prototype.commentText = null;
Comments.prototype.pagerBlock = null;
Comments.prototype.titleBlock = null;
Comments.prototype.page = null;
Comments.prototype.lastPage = null;
Comments.prototype.perPage = null;
Comments.prototype.replyForm = null;
Comments.prototype.replyButton = null;

Comments.prototype.draw = function(){
	Comments.instance = this;
	this.getComments();
};

Comments.prototype.getComments = function(){
	$.ajax({
		url: Opa.baseUrl + '/comments/' + this.type + '/' + this.related_to + '?page=' + this.page + '&last=' + this.lastPage+'&perPage='+this.perPage,
		dataType: 'json'
	}).done(function (data) {
		if (data.status == 'error') {
			Opa.showMessage(data.message, 'danger');
		} else {
			Comments.instance.lastPage = false;
			Comments.instance.commentText = null;
			Comments.instance.drawTitle(data.total, data.authorized);
			Comments.instance.drawForm(data.authorized);
			Comments.instance.drawComments(data.comments, data.authorized);
			Comments.instance.drawPagination(data.pagination);
		}
	}).fail(function (error) {
		Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
	});
};

Comments.prototype.drawTitle = function(total, authorized){
	if (authorized || (!authorized && total > 0)) {
		if (this.titleBlock != null) {
			removeNode(this.titleBlock);
		}

		this.titleBlock = mkE({
			tag: 'h4',
			className: 'categoryTitle boldMedium',
			text: total + ' ' + Lang.choice('comments.title', Opa.isPlural(total))
		}).append(this.container);
	}
}

Comments.prototype.drawComments = function (data, authorized) {
	if (this.node != null) {
		removeNode(this.node);
	}
	this.node = mkE({
		tag:'div',
		className: 'comments',
	});
	var comments = JSON.parse(data);
	this.page = comments.current_page;
	this.createComment(comments.data, authorized);
	this.node.append(this.container);
};

Comments.prototype.createComment = function (data, authorized) {
	for(var i = 0; i < data.length; i++){
		var comData = data[i];
		var reply = (comData.replies == undefined ? true: false);

		var comment = mkE({
			tag:'div',
			className: 'row singleComment '+( reply ? 'reply' : ''),
			els:[
				{
					tag: 'a',
					href: Opa.baseUrl + '/profile/' + comData.user.id,
					els: [
						{
							tag: 'img',
							className: 'commentAvatar',
							attr: {
								src: comData.user.avatar
							},
						}
					]
				},
				{
					tag:'div',
					className: 'commentBody',
					els:[
						{
							tag:'div',
							className: 'commentHeader',
							els:[
								{
									tag: 'a',
									href: Opa.baseUrl + '/profile/'+comData.user.id,
									className: 'blueText boldMedium',
									innerHTML: comData.user.name
								},
								{
									tag: 'span',
									className: 'grayText small12 created',
									innerHTML: comData.created
								}
							]
						},
						{
							tag: 'div',
							className: 'commentTextAdded',
							innerHTML: comData.text,
						}
					]
				},
			]
		});

		if (authorized) {
			var buttons = mkE({
				tag : 'div',
				className: 'pull-right',
				els:[
					{
					tag: 'a',
					className: 'blueText smallFont',
					id: 'li_'+comData.id,
					els:[
						{
							tag: 'span',
							className: 'fa fa-thumbs-up',
							style: {
								marginRight: '5px'
							}
						},
						{
							tag: 'span',
							className: 'likesCount',
							innerHTML: (comData.userLikeComment ? Lang.get('comments.dislike').toUpperCase() : Lang.get('comments.like').toUpperCase()) + ' / ' + comData.likesCount
						}
					],
					prop:{
						onclick: function(){Comments.instance.likeComment(this)}
					}
				}
				]
			});

			if (!reply) {
				mkE({
					tag: 'a',
					className: 'blueText replyComment smallFont',
					id: 'cm_'+comData.id,
					els:[
						{
							tag: 'span',
							className: 'fa fa-reply',
							style:{
								marginRight: '5px'
							}
						},
						{
							tag: 'span',
							text: Lang.get('comments.reply').toUpperCase()
						}
					],
					prop: {
						onclick: function () {
							Comments.instance.drawReplyForm(authorized, this.parentNode, this);
						}
					}
				}).append(buttons);
			}
			buttons.append(comment);
		}
		comment.append(Comments.instance.node);

		if (comData.replies != undefined && comData.replies.length > 0) {
			Comments.instance.createComment(comData.replies, authorized);
		}
	}
};

Comments.prototype.drawForm = function(authorized){
	if (authorized) {
		if (this.form != null) {
			removeNode(this.errorMsg);
			removeNode(this.form);
		}

		this.errorMsg = mkE({
			tag: 'div',
			className: 'hidden alert',
			attr:{
				role: 'alert'
			},
		}).append(this.container);

		this.form = mkE({
			tag:'div',
			className: 'row',
			els:[
				{
					tag: 'div',
					style:{display:'none'},
					els:[
						{
							tag: 'input',
							name: 'title',
							id:'texttitle',
							attr:{value:'d581175aced39e49f229a9e11e148cd9'}
						}
					]
				},
				{
					tag:'textarea',
					className: 'commentText',
					attr: {
						placeholder: Lang.get('comments.comment'),
						maxlength:'200'
					},
					prop:{
						onkeyup: function(){Comments.instance.textCounter(this, 'textcnt'); },
						onkeydown: function(){Comments.instance.textCounter(this, 'textcnt');}
					}
				},
				{
					tag:'div',
					className: 'commentTextFooter',
					els:[
						{
							tag: 'div',
							text: this.maxlimit + ' '+Lang.choice('comments.charCounter', Opa.isPlural(this.maxlimit)),
							id: 'textcnt',
							className: 'commentCharCounter commentText'
						},
						{
							tag: 'a',
							className: 'btn btn-dr-orange pull-right',
							text: Lang.get('comments.commentBtn'),
							prop: {
								onclick: function(){Comments.instance.addComment()}
							}
						}
					]
				}
			]
		});
		this.form.append(this.container);
	}
};

Comments.prototype.drawReplyForm = function (authorized, element, button) {
	if (authorized) {
		if (this.replyForm != null) {
			removeNode(this.replyForm);
			removeClassName(this.replyButton, 'hidden');
			this.commentText = null;
		}

		var commentId = button.id.replace('cm_','');
		mkE({tag:'div', className: 'clearfix'}).append(element.parentNode);
		this.replyButton = button;
		this.replyForm = mkE(
			{
				tag: 'div',
				className: 'row replyForm',
				els: [
					{
						tag: 'div',
						style:{display:'none'},
						els:[
							{
								tag: 'input',
								name: 'title',
								id: 'texttitle'+commentId,
								attr:{value:'d581175aced39e49f229a9e11e148cd9'}
							}
						]
					},
					{
						tag: 'textarea',
						className: 'commentText',
						attr: {
							placeholder: Lang.get('comments.comment'),
							maxlength: '200',
						},
						prop: {
							onkeyup: function () {
								Comments.instance.textCounter(this, 'textcnt'+commentId);
							},
							onkeydown: function () {
								Comments.instance.textCounter(this, 'textcnt'+commentId);
							}
						}
					},
					{
						tag: 'div',
						className: 'commentTextFooter',
						els: [
							{
								tag: 'div',
								text: this.maxlimit + ' ' +Lang.choice('comments.charCounter', this.maxlimit),
								id: 'textcnt'+commentId,
								className: 'commentCharCounter commentText'
							},
							{
								tag: 'a',
								className: 'btn btn-dr-orange pull-right',
								text: Lang.get('comments.commentBtn'),
								prop: {
									onclick: function () {
										Comments.instance.addComment(commentId)
									}
								}
							}
						]
					}
				]
			}).append(element.parentNode);

		addClassName(this.replyButton, 'hidden');
	}
};

Comments.prototype.addComment = function(parent){
	var parent = typeof parent !== 'undefined' ? parent : null;
	var title = (parent != null ? $('#texttitle'+parent).val() : $('#texttitle').val());

	$.ajax({
		url : Opa.baseUrl + '/comments/add',
		dataType: 'json',
		method: 'POST',
		data:{
			_token: Opa.csrfToken,
			type: this.type,
			relatedTo: this.related_to,
			text: this.commentText,
			title: title,
			parent: parent
		}
	}).done(function (data) {
		if (data.status == 'error') {
			Comments.instance.showMessage(data.message, 'danger');
		} else {
			this.commentText = null;
			if (parent != null) {
				Comments.instance.lastPage = false;
			} else {
				Comments.instance.lastPage = true;
			}
			// Redraw comments block.
			Comments.instance.draw();
		}
	}).fail(function (error) {
        if (error && error.status == 401) {
            document.location.href = '/loginform';
            return;
        }
		Comments.instance.showMessage('Kļūda saglabājot', 'danger', '', '', error);
	});
};

Comments.prototype.textCounter = function(field, counter){
	var cntfield = document.getElementById(counter);
	this.commentText = field.value;
	if (field.value.length > this.maxlimit){
		field.value = field.value.substring(0, this.maxlimit);
	} else {
		var left = this.maxlimit - field.value.length;
		cntfield.innerHTML = left + ' ' + Lang.choice('comments.charCounter', Opa.isPlural(left));
	}
};

Comments.prototype.showMessage = function(message, type){
	removeClassName(this.errorMsg, 'hidden');
	removeClassName(this.errorMsg, 'alert-danger alert-success');
	addClassName(this.errorMsg, 'alert-'+type);
	this.errorMsg.innerHTML = message;
	setTimeout(function(){addClassName(Comments.instance.errorMsg, 'hidden');}, 2000);
};

Comments.prototype.drawPagination = function(pagination) {
	if (this.pagerBlock != null) {
		removeNode(this.pagerBlock);
	}
	this.pagerBlock = mkE({
		tag:'div',
		className: 'text-center darkPagination commentPager',
		innerHTML: pagination
	});
	this.pagerBlock.append(this.container);

	$('.commentPager .pagination a').each(function(e){
		$(this).attr('data',$(this).attr('href')+'&perPage=' + Comments.instance.perPage);
		$(this).attr('href','javascript:');
		$(this).bind('click',function(){
			$.ajax({
				url: $(this).attr('data')
			}).done(function( data ) {
				Comments.instance.drawComments(data.comments, data.authorized);
				Comments.instance.drawPagination(data.pagination);
			});
		});
	});
};

Comments.prototype.likeComment = function(comment){
	var commentId = comment.id.replace('li_', '');
	$.ajax({
		url : Opa.baseUrl + '/comments/like',
		dataType: 'json',
		method: 'POST',
		data:{
			_token: Opa.csrfToken,
			comment: commentId
		}
	}).done(function (data) {
		if (data.status == 'error') {
			Comments.instance.showMessage(data.message, 'danger');
		} else {
			comment.lastChild.innerHTML = (data.like ? Lang.get('comments.dislike').toUpperCase() : Lang.get('comments.like').toUpperCase()) + ' / ' + data.likes;
		}
	}).fail(function (error) {
		Comments.instance.showMessage('Kļūda saglabājot', 'danger', '', '', error);
	});
};