/**
 * @constructor
 * @param {Object} par
 */
Opa.services = function (par) {
	if( par.error ){
		par.node.innerHTML = par.error;
		par.node.style.padding = '10px';
		par.node.style.boxSizing = 'border-box';
		return;
	}
	for (var i=0; i< par.co.length;i++){
		if(par.co[i].number == par.count) {
			this.cardAmount = par.co[i].price;
		}
	}
	this.dayCount = par.count;
	this.specType = par.type;
	this.name = par.name;
	this.par = par;
	this.callback = new Function(par.callback || '');
	par.customId = par.customId || 0;
	this.reason = (par.reason != null) ? par.reason : null;
	this.disablePhoneReg = par.disablePhoneReg || false;
	this.discountDescr = par.short_caption;

	if (this.specType == Opa.services.TYPE_DISCOUNT && this.dayCount == 60) {
		this.discountDescr = this.discountDescr.replace("30", "60");
	}

	mkE({
		tag: 'table',
		className: 'pbDescr',
		els: [
			{
				tag: 'tbody',
				els: [
					{
						tag: 'tr',
						els: [
							{
								tag: 'td',
								attr: {
									width: 140
								},
								els: [
									{
										tag: 'img',
										className: 'serviceIcon',
										src: par.icon
									} // img
								]
							}, // td
							{
								tag: 'td',
								els: [
									this._hNode = mkE({
										tag: 'h1',
										id: 'pbCaption',
										text: par.caption
									}),
									this._descrNode = mkE({
										tag: 'p',
										innerHTML: this.discountDescr
									})
								]
							} // td
						]
					} // tr
				]
			} // tbody
		]
	}).appendTo(par.node);

	var h1 = mkE({
		tag: 'h2',
		els: [
			{
				tag: 'span',
				prop: {
					innerHTML: '&nbsp;'
				}
			},
			{
				tag: 'span',
				className: 'pbTabs',
				els: [
					this.loadContent()
				]
			}
		]
	}).appendTo(par.node);
	if (empty(par.co) || par.disableCredits) {
		if (this._tabCredit) {
			this._tabCredit.style.display = 'none';
		}
	}
	if (!par.canPayCard || par.disableCard) {
		if (this._tabCC) {
			this._tabCC.style.display = 'none';
		}
	}
	if (!par.canPayBanklink || !par.banklinks || par.disableBanklinks) {
		if (this._tabBanklink) {
			this._tabBanklink.style.display = 'none';
		}
	}
	if (empty(par.so)) {
		if (this._tabSMS) {
			this._tabSMS.style.display = 'none';
		}
	}
	this.setCreditCount(par.credits);
	// sms cc
	if( !empty( par.so ) ){
		var smsOption = par.so;
		var items = [];
		var r;
		for (var k in par.ccs) {
			r = par.ccs[ k ];
			items.push({
				caption: '<img src="/img/' + r + '.png" />',
				onclick:Opa.closure( this, function(r){
					$.ajax({
						url : Opa.baseUrl + '/postMessage/getCCSMSOptions',
						dataType: 'json',
						method: 'POST',
						data:{
							_token: Opa.csrfToken,
							name: this.name,
							cc: r
						}
					}).done(function (re) {
						if(re.so) {
							this.par.so = re.so;
							Opa.services.smsPaymentDrawOptions();
						}
					});
				}, r ),
				selected: smsOption.cc == r
			});
		}
		this.ccDropDown = Opa.dropDown(items);
		this.ccDropDown.style.display = 'none';
		if (count( par.ccs ) > 1) {
			this.ccDropDown.append(h1);
		}
	}

	this.isCredit = !empty(par.co);
	this.isSMS = !empty(par.so);
	this.banklinks = par.banklinks;
	this.content = mkE({
		tag: 'div',
		className: 'pbCont'
	}).appendTo(par.node);

	if (this.isSMS && par.showSMS) {
		this.show('sms');
		return;
	}
	if (this.specType == 'sms') {
		this.show('sms');
	}
	else if (this.specType == "cred") {
		this._tabSMS.style.display = 'none';
		this.show('credit');
	}
	else if (this.specType == Opa.services.TYPE_DISCOUNT) {
		this._tabSMS.style.display = 'none';
		this.show('credit');
	}
	else if (
		!par.disableCredits && (
			( this.isCredit && reset(par.co).price <= par.credits ) ||
			( this.isCredit && par.cardSaved ) || !this.isSMS ||
			( this.isCredit && !par.hasPhone )
		) ) {
		this.show('credit');
	}
	else if( par.canPayCard && !par.disableCard ) {
		this.show('cc');
	}
	else if( par.canPayBanklink && !par.disableBanklinks ) {
		this.show('banklink');
	} else {
		this.show('sms');
	}
};

Opa.services.TYPE_DISCOUNT = "cardBank";

Opa.services.CURRENCY_EUR = 'EUR';
Opa.services.CURRENCY_USD = 'USD';
Opa.services.CURRENCY_GBP = 'GBP';

Opa.services.TYPE_CARD = 'card';
Opa.services.TYPE_HANZANET = 'hanzanet';
Opa.services.TYPE_ELINK = 'elink';
Opa.services.TYPE_NORDEA = 'nordea';
Opa.services.TYPE_DIGILINK = 'digilink';
Opa.services.TYPE_INORD = 'inord';
Opa.services.TYPE_PAYPAL = 'paypal';
Opa.services.TYPE_MOBILLY = 'mobilly';

Opa.services.prototype = {
	loadContent:function() {
		var titleText = '';
		titleText = Lang.get('Mans maciņš');
		if (this.specType == Opa.services.TYPE_DISCOUNT) {
			titleText = "Karte un internetbanka";
		}
		return [
			this._tabCredit = mkE({
				tag: 'span',
				className: 'radius3top pbTabsSel',
				text: titleText,
				prop: {
					onclick:Opa.closure( this, function(){
						this.show('credit');
					} )
				}
			}),
			this._tabCC = mkE({
				tag: 'span',
				className: 'radius3top',
				text: Lang.get('karte'),
				prop: {
					onclick:Opa.closure( this, function(){
						this.show('cc');
					} )
				}
			}),
			this._tabBanklink = mkE({
				tag: 'span',
				className: 'radius3top',
				text: Lang.get('bankas'),
				prop: {
					onclick:Opa.closure( this, function(){
						this.show('banklink');
					} )
				}
			}),
			this._tabSMS = mkE({
				tag: 'span',
				className: 'radius3top',
				text: 'SMS',
				prop: {
					onclick:Opa.closure( this, function(){
						this.show('sms');
					} )
				}
			})
		]
	},

	_tab: function (type) {
		switch (type) {
			case 'credit':
				addClassName(this._tabCredit, 'pbTabsSel');
				if (this._tabSMS) {
					removeClassName(this._tabSMS, 'pbTabsSel');
				}
				if (this._tabCC) {
					removeClassName(this._tabCC, 'pbTabsSel');
				}
				if (this._tabBanklink) {
					removeClassName(this._tabBanklink, 'pbTabsSel');
				}
				break;
			case 'cc':
				addClassName(this._tabCC, 'pbTabsSel');
				if (this._tabSMS) {
					removeClassName(this._tabSMS, 'pbTabsSel');
				}
				if (this._tabCredit) {
					removeClassName(this._tabCredit, 'pbTabsSel');
				}
				if (this._tabBanklink) {
					removeClassName(this._tabBanklink, 'pbTabsSel');
				}
				break;
			case 'banklink':
				addClassName(this._tabBanklink, 'pbTabsSel');
				if (this._tabSMS) {
					removeClassName(this._tabSMS, 'pbTabsSel');
				}
				if (this._tabCredit) {
					removeClassName(this._tabCredit, 'pbTabsSel');
				}
				if (this._tabCC) {
					removeClassName(this._tabCC, 'pbTabsSel');
				}
				break;
			case 'sms':
				addClassName(this._tabSMS, 'pbTabsSel');
				removeClassName(this._tabCredit, 'pbTabsSel');
				if (this._tabCC) {
					removeClassName(this._tabCC, 'pbTabsSel');
				}
				if (this._tabBanklink) {
					removeClassName(this._tabBanklink, 'pbTabsSel');
				}
				break;
		}
	},

	show: function (type) {
		this.type = type;
		clearNode(this.content);
		if (this.ccDropDown) {
			this.ccDropDown.style.display = 'none';
		}
		switch (type) {
			case 'credit':
				if (this.creditPayment_node) {
					this.creditPayment_node.append(this.content);
					break;
				}
				this.creditPayment();
				break;
			case 'cc':
				if (this.ccPayment_node) {
					this.ccPayment_node.append(this.content);
					this.purchaseWithCard._pbLoaded();
					break;
				}
				this.ccPayment();
				break;
			case 'banklink':
				if (this.banklinkPayment_node) {
					this.banklinkPayment_node.append(this.content);
					this.purchaseWithBanklink._pbLoaded();
					break;
				}
				this.banklinkPayment();
				break;
			case 'sms':
				if (this.ccDropDown) {
					this.ccDropDown.style.display = '';
				}
				if (this.smsPayment_node) {
					this.smsPayment_node.append(this.content);
					break;
				}
				this.smsPayment();
				break;
		}
		this._tab(type);
		this.setTitle();
	},

	setTitle:function(){
		if( !this.par.apiTrId ){
			return;
		}
		switch( this.type ){
			case 'credit':
				this._hNode.innerHTML = this.creditOption.caption.split(' - ')[0];
				this._descrNode.innerHTML = this.par.short_credit;
				break;
			case 'sms':
				this._hNode.innerHTML = this.smsOption.caption.split(' - ')[0];
				this._descrNode.innerHTML = this.par.short_caption;
				break;
		}
	},

	// +++ credit +++
	setCreditCount: function(c){
		this.credits = c;
		if( !this.creditCount ){
			return;
		}

		if(c){
			this.creditCount.innerHTML = Lang.get('Tavā maciņā') + ' <span>&euro;</span> <b>' + parseFloat( c / 100 ).toFixed(2) + '</b>';
		} else {
			this.creditCount.innerHTML = Lang.get('Maciņā nav līdzekļu');
		}
		return;
	},

	creditPayment: function(){
		var form;
		var buyButtonPrice;
		this.creditPayment_table = mkE({
			tag: 'div',
			className: 'pbCredits',
			els: [
				form = mkE({
					tag: 'form'
				}),
				this.creditCount = mkE({
					tag: 'div',
					className: 'pbUserCredits'
				}),
				this.creditPaymet_submitButton = Opa.submitButton({
					type: 'submit',
					color: 'buy',
					caption: Lang.get('Pirkt'),
					onclick:Opa.closure( this, function(){
						if( this.par.apiTrId ){
							this.payApiTr();
							return;
						}
					} ), // onclick
				}) // submitButton
			]
		});
		this.setCreditCount(this.credits);
		this.creditDelayInfo = Opa.info(Lang.get('Ja Tavā maciņā vēl nav naudas, nesatraucies – dažreiz banku operācijas mazliet kavējas. Ieskaties pēc brīža.'));
		this.creditDelayInfo.style.display = 'none';
		this.creditPayment_node = mkE({
			tag: 'div',
			els: [
				this.creditDelayInfo,
				this.creditPayment_table
			]
		}).appendTo(this.content);

		var buyButtonPriceSet = Opa.closure( this, function(){
			this.setTitle();
			var text = Lang.get('maksā ar maciņu') + ' ';
			text += '&euro; ' + ( this.creditOption.price / 100 ).toFixed(2)

			this.setCreditCount(this.credits);
			if( this.creditOption.price > this.credits ){
				removeClassName(this.creditPaymet_submitButton, 'buttonBuy');
				addClassName(this.creditCount, 'notEnough');
				this.creditPaymet_submitButton.innerHTML = Lang.get('Pirkt pakalpojumu');

				if( this.credits ){
					this.creditCount.innerHTML += '<b> / ' + Lang.get('Nepietiek līdzekļu') + '</b>';
				}
			}else{
				addClassName(this.creditPaymet_submitButton, 'buttonBuy');
				this.creditPaymet_submitButton.innerHTML = text;
				removeClassName(this.creditCount, 'notEnough');
			}
		} );

		var r = this.par.co;
		this.creditOption = r;

		buyButtonPriceSet();
		mkE({
			tag: 'div',
			prop: {
				innerHTML: r.caption
			}
		}).append(form);
	},

	payApiTr:function(){
		Opa.loadingOverlay(this.content);
		var instance = this;
		$.ajax({
			url : Opa.baseUrl + '/postMessage/payApiTr',
			dataType: 'json',
			method: 'POST',
			data:{
				_token: Opa.csrfToken,
				trid: this.par.apiTrId,
				uid: this.par.uid
			}
		}).done(function (re) {
			instance._payApiTr(re);
		});
	},

	_payApiTr:function(re){
		var $pb = this;
		Opa.removeLoadingOverlay(this.content);
		if( this.credits ){
			this.setCreditCount(re.credits);
		}
		if( re.ok ){
			this.paymentOk();
			return;
		}
		var buy;
		var p = new Opa.services.PurchaseCredits__par;
		p.count = $pb.creditOption.price;
		p.uid = this.par.uid;
		p.onBuy = function () {
			$pb.payApiTr();
			buy.remove();
			$pb.setCreditCount($pb.credits);
		};
		p.onClose = function () {
			$pb.creditDelayInfo.style.display = '';
			$pb.creditPayment_table.style.display = '';
			buy.remove();
		};
		buy = new Opa.services.PurchaseCredits(p);

		$pb.creditPayment_table.style.display = 'none';
		Opa.insertAfter(buy.node, this.creditPayment_table);
	},
	// --- credit ---

	// +++ card +++
	ccPayment: function(){
		var p = new Opa.services.PurchaseWithCard__par;
		p.service = new Opa.services.PurchaseCredits__service({
			caption: this.par.caption,
			name: this.name,
			options: this.par.co
		});
		p.services = this;
		p.onClose = Opa.closure( this, function(){
			this.purchaseWithCard.remove();
			this.cardDelayInfo.style.display = '';
		} );
		p.onBuy = Opa.closure( this, function(){
			this.purchaseWithCard.remove();
			this.canAddAuto = false;
			this.paymentOk();
		} );

		this.purchaseWithCard = new Opa.services.PurchaseWithCard(p);

		this.cardDelayInfo = Opa.info(Lang.get('card_delay_info'));
		this.cardDelayInfo.style.display = 'none';

		this.ccPayment_node = mkE({
			tag: 'div',
			els: [
				this.cardDelayInfo,
				this.purchaseWithCard
			]
		}).appendTo(this.content);
	},
	// --- card ---

	// +++ banklink +++
	banklinkPayment: function(){
		var p = new Opa.services.PurchaseWithBanklink__par;
		p.service = new Opa.services.PurchaseCredits__service({
			caption: this.par.caption,
			name: this.name,
			options: this.par.co
		});
		p.services = this;
		p.onClose = Opa.closure( this, function(){
			this.purchaseWithBanklink.remove();
			this.banklinkDelayInfo.style.display = '';
		} );
		p.onBuy = Opa.closure( this, function(){
			this.purchaseWithBanklink.remove();
			this.canAddAuto = false;
			this.paymentOk();
		} );

		this.purchaseWithBanklink = new Opa.services.PurchaseWithBanklink(p);

		this.banklinkDelayInfo = Opa.info(Lang.get('card_delay_info'));
		this.banklinkDelayInfo.style.display = 'none';

		this.banklinkPayment_node = mkE({
			tag: 'div',
			els: [
				this.banklinkDelayInfo,
				this.purchaseWithBanklink
			]
		}).appendTo(this.content);
	},
	// --- banklink ---

	// +++ sms +++
	smsPayment:function(){
		this.smsPayment_node = mkE({
			tag: 'div',
			className: 'pbSMS',
			els: [
				this.smsPayment_form = mkE({
					tag: 'form'
				}),
				this.smsStep2 = mkE({
					tag: 'div'
				})
			]
		}).appendTo(this.content);
		if( this.par.smsDownText ){
			clearNode( this.smsPayment_node );
			Opa.error( this.par.smsDownText ).append(this.smsPayment_node);
		}
		this.smsPaymentDrawOptions();
	},

	smsPaymentDrawOptions:function(){
		clearNode(this.smsPayment_form);
		var r = this.par.so;
		mkE({
			tag: 'div',
			prop: {
				innerHTML: r.caption
			}
		}).append( this.smsPayment_form );
		this.smsOption = r;
		this.smsPaymentChange();
	},

	smsPaymentChange: function(){
		this.setTitle();
		Opa.loadingOverlay(this.content);
		var instance = this;
		$.ajax({
			url : Opa.baseUrl + '/postMessage/smsPaymentOption',
			dataType: 'json',
			method: 'POST',
			data:{
				_token: Opa.csrfToken,
				apiTrId: this.par.apiTrId || false,
				name: this.name,
				count: this.smsOption.number,
				cc: this.smsOption.cc,
				customId: this.par.customId,
				uid: this.par.uid
			}
		}).done(function (re) {
			instance._smsPaymentChange(re);
		});
	},

	_smsPaymentChange:function(re){
		var $pb = this;
		Opa.removeLoadingOverlay( this.content );
		clearNode( this.smsStep2 );
		var vai = false;
		if( re.item.canAddAuto ){
			this.canAddAuto = true;
		}

		Opa.hrText('').append(this.smsStep2);

		var text = Lang.get('Kodu CODE sūti uz PHONE').replace( 'CODE', re.item.keyword + ' ' + re.item.num ).replace( 'PHONE', re.item.phone );
		switch( re.item.cc ){
			case 'lv':
				text += ' (LMT, TELE2, BITE)';
				break;
			case 'lt':
				text += ' (BITE, OMNITEL, TELE2)';
				break;
			case 'hu':
				text += ' (T-MOBILE, PANNON, VODAFONE)';
				break;
		}
		mkE({
			tag: 'center',
			els: [
				mkE({
					tag: 'div',
					prop: {
						innerHTML: text
					}
				}),
				mkE({
					tag: 'a',
					text: Lang.get('Kad saņem atbildes sms spied šeit'),
					href: 'javascript:',
					prop: {
						onclick: function () {
							Opa.loadingOverlay($pb.content);

							$.ajax({
								url : Opa.baseUrl + '/postMessage/smsPaymentOk',
								dataType: 'json',
								method: 'POST',
								data:{
									_token: Opa.csrfToken,
									keyword: re.item.keyword,
									num: re.item.num,
								}
							}).done(function (re) {
								Opa.removeLoadingOverlay($pb.content);
								if (re.charged > 0) {
									$pb.paymentOk();
								} else if (re.charged < 0) {
									$pb.paymentFail(re);
								} else {
									alert( Lang.get('SMS maksājums nav veikts') );
								}
							});
						}
					}
				})
			]
		}).append(this.smsStep2);
	},
	// --- sms ---

	paymentOk: function(){
		var $pb = this;
		clearNode(this.content);
		if (this.more) {
			this.more.remove();
		}
		if (this.specType == Opa.services.TYPE_DISCOUNT) {
			window.location = "/";
			return;
		}
		Opa.success(Lang.get('Maksājums saņemts')).append(this.content);
		setTimeout( Opa.closure( this, Opa.reload()), 2000 );
	},

	paymentFail:function (par){
		clearNode(this.content);
		var text = Lang.get('Kļūda veicot maksājumu');
		if (par.extra && Lang.exists('king_sms_' + par.extra)) {
			text += ': ' + Lang.get('king_sms_' + par.extra);
		}
		Opa.error(text).append(this.content);
	}
};

// +++ add credits +++
/**
 * @param {Object} [par]
 * @constructor
 */
Opa.services.PurchaseCredits__par = function( par ) {
	O2O( this, par || {} );
};
Opa.services.PurchaseCredits__par.prototype.bid = 0;
Opa.services.PurchaseCredits__par.prototype.price = 0;
/** @deprecated */
Opa.services.PurchaseCredits__par.prototype.count = 0;
Opa.services.PurchaseCredits__par.prototype.showPointsback = false;
Opa.services.PurchaseCredits__par.prototype.callback = function(){};
Opa.services.PurchaseCredits__par.prototype.onBuy = function(){};
Opa.services.PurchaseCredits__par.prototype.onClose = function(){};
Opa.services.PurchaseCredits__par.prototype.onFailed = function(){};
/** @type {Opa.services.PurchaseCredits__service} */
Opa.services.PurchaseCredits__par.prototype.service = null;
Opa.services.PurchaseCredits__par.prototype.credits = 0;
Opa.services.PurchaseCredits__par.prototype.drTrans = null;
Opa.services.PurchaseCredits__par.prototype.apiTrans = null;
Opa.services.PurchaseCredits__par.prototype.uid = null;


/**
 * @extends {mkE.Base}
 * @param {Opa.services.PurchaseCredits__par|Object} [par]
 * @constructor
 */
Opa.services.PurchaseCredits = function( par ) {
	par = new Opa.services.PurchaseCredits__par( par );
	this.callback = par.callback;
	this.specType = par.specType;
	this.cardAmount = par.cardAmount;
	this.dayCount = par.dayCount;
	this.onBuy = par.onBuy;
	this.onClose = par.onClose;
	this.onFailed = par.onFailed;
	this.price = par.price || par.count || 0;
	this.totalCount = par.totalCount || 0;
	this._showPointsback = false;
	this._steps = {};
	this.service = par.service;
	this.credits = par.credits || 0;
	this.drTrans = par.drTrans;
	this.apiTrans = par.apiTrans;
	this.uid = par.uid;
	this.node = mkE( {
		tag: 'div',
		className: 'purchaseCredits'
	} );
	this._draw();
	this._getData();
};

Opa.services.PurchaseCredits.prototype = new mkE.Base;
/** @type {Object.<string,mkE.Base>} */
Opa.services.PurchaseCredits.prototype._steps = {};
Opa.services.PurchaseCredits.prototype._pb = {};
Opa.services.PurchaseCredits.prototype._amountBtns = {};
Opa.services.PurchaseCredits.prototype._selectedAmount = null;
Opa.services.PurchaseCredits.prototype._selectedMethod = null;
Opa.services.PurchaseCredits.prototype.creditOption = {};

Opa.services.PurchaseCredits.prototype._draw = function() {
	var p;

	p = new Opa.services.PurchaseCreditsStep__par;
	p.title = (this.service ? '2' : '1') + '. ' + Lang.get('Izvēlies summu');

	p.className = 'amount';
	p.els = [
		this._amountOptions = mkE( {
			tag: 'div',
			className: 'amountOptions'
		} )
	];

	p.rightTitle = this._selectedAmountNode = mkE( {
		tag: 'span',
		className: 'selectedAmount'
	} );
	this._steps.amount = new Opa.services.PurchaseCreditsStep(p);

	p = new Opa.services.PurchaseCreditsStep__par;
	p.title = (this.service ? '3' : '2') + '. ' + Lang.get('Izvēlies maksāšanas veidu');
	p.className = 'method';
	p.disabled = true;

	p.els = [
		this._methods = new Opa.services.PurchaseCreditsMethods,
	];

	this._steps.method = new Opa.services.PurchaseCreditsStep(p);

	p = {};
	p.caption = Lang.get('papildināt maciņu par') + ' 3 &euro;';
	p.className = 'purchaseBtn';
	p.onclick = Opa.closure( this, this._purchaseCredits );
	p.disabled = true;

	this._footer = new Opa.Footer( {
		className: 'purchaseContainer',
		els: [
			this._purchaseBtn = new Opa.submitButton(p)
		]
	} );
	this._footer.hide();

	var els = [
		this._errorNode = Opa.error( Lang.get('Maksājums ar karti neizdevās') ),
		{
			tag: 'ul',
			els: this._steps
		},
		this._footer
	];
	mkE.appendTypeElement( this.node, els );
	this._errorNode.style.display = 'none';
};

Opa.services.PurchaseCredits.prototype._getData = function() {
	Opa.loadingOverlay( this.node );
	var instance = this;
	$.ajax({
		url : Opa.baseUrl + '/postMessage/purchaseOptions',
		dataType: 'json',
		method: 'POST',
		data:{
			_token: Opa.csrfToken,
			price: this.price,
			amount: this.totalCount,
		}
	}).done(function (re) {
		instance._getDataRe(re);
	});
};

/**
 * @param {Opa.services.PurchaseCredits__getDataRe} re
 * @private
 */
Opa.services.PurchaseCredits.prototype._getDataRe = function( re ) {
	re = new Opa.services.PurchaseCredits__getDataRe(re);

	this._currentMethod = re.method;
	this._currentCurrency = re.currency;
	this._autoAvailable = re.auto;
	this._rates = re.rates;

	clearNode(this._methods._cards);
	clearNode(this._methods._banks);
	clearNode(this._methods._other);

	for ( var type in re.methods ) {
		var method = re.methods[type];

		var p = new Opa.services.PurchaseCreditsMethodItem__par;
		p.type = type;
		p.caption = method.name;
		p.currencies = method.currencies;
		p.amounts = method.amounts;
		p.onclick = Opa.closure( this, this._selectPaymentMethod );
		p.selected = ( type == this._currentMethod );
		var item = new Opa.services.PurchaseCreditsMethodItem( p );
		this._methods.addMethod( item );

		if ( type == this._currentMethod ) {
			this._selectedMethod = item;
		}
	}

	//var ddPar = {};
	//ddPar.caption = this._currentCurrency;
	//ddPar.visible = ( this._currentMethod == Opa.services.TYPE_PAYPAL );
	//ddPar.items = [];
	//
	//if( re.methods[ Opa.services.TYPE_PAYPAL ] ){
	//	for ( var j in re.methods[ Opa.services.TYPE_PAYPAL ].currencies ) {
	//		var currency = re.methods[ Opa.services.TYPE_PAYPAL ].currencies[j];
	//		var p = {};
	//		p.caption = currency;
	//		p.selected = (currency == this._currentCurrency);
	//		p.onclick = Opa.closure( this, this._changeCurrency, currency );
	//		ddPar.items.push( new Opa.DropDownItem(p) );
	//	}
	//}
	//
	//this._currencyDD = new Opa.dropDown( ddPar );
	//this._currencyDD.append( this._steps.amount._rightTitle );

	this._drawAmounts();
	Opa.removeLoadingOverlay( this.node );
};

Opa.services.PurchaseCredits.prototype._selectPaymentMethod = function( method ) {
	if ( this._selectedMethod && this._selectedMethod.type == method.type ) {
		return;
	}
	if ( this._selectedMethod.type == Opa.services.TYPE_PAYPAL
		|| method.type == Opa.services.TYPE_PAYPAL ) {

		if ( method.type != Opa.services.TYPE_PAYPAL ) {
			this._currencyDD.hide();
		} else {
			this._currencyDD.show();
		}

		if ( !isset( method.amounts[ this._currentCurrency ] )
			|| array_search( this._selectedAmount, method.amounts[ this._currentCurrency ] ) === false ) {

			this._selectedAmount = null;
			this._steps.amount.expand();
			this._steps.method.disable();
			this._footer.hide();
			this._purchaseBtn.disabled= true;
		}
	} else {
		if ( this._selectedAmount ) {
			this._purchaseBtn.disabled = false;
		}
	}

	this._currentMethod = method.type;
	this._selectedMethod = method;

	this._updateAmountRightTitle();
	this._drawAmounts();
};

Opa.services.PurchaseCredits.prototype._updatePurchaseBtn = function() {
	var amount = (this._selectedAmount / 100).toFixed(2);
	this._purchaseBtn.innerHTML = Lang.get('Pirkt pakalpojumu par') + ' '+ amount +' ' + this._getCurrencySymbol();
};

Opa.services.PurchaseCredits.prototype._updateAmountRightTitle = function() {
	var amountText;
	if ( this._selectedAmount ) {
		if ( this._currentMethod == Opa.services.TYPE_PAYPAL ) {
			amountText = (this._selectedAmount / 100).toFixed(2);
		} else {
			amountText = (this._selectedAmount / 100).toFixed(2) + ' ' + this._currentCurrency
		}
	} else {
		amountText = '';
	}

	setNodeText( this._selectedAmountNode, amountText );
};

Opa.services.PurchaseCredits.prototype._drawAmounts = function() {
	clearNode( this._amountOptions );

	var amounts = this._selectedMethod.amounts[this._currentCurrency];
	var c = 0;

	for ( var k in amounts ) {
		var mount = amounts[k];
		for (var r in mount) {
			var amount = mount[r];

			var p = {};
			p.caption = (amount / 100).toFixed(2) + ' ' + this._getCurrencySymbol( this._currentCurrency );
			p.className = 'amountItem amountBtn' + ( ++c % 4 == 0 ? ' fourthItem' : '' );
			if ( this._selectedAmount && this._selectedAmount == amount ) {
				p.className += ' selected';
			}
			p.onclick = Opa.closure( this, this._selectAmount, amount );

			var btn = new Opa.button(p);
			btn.append( this._amountOptions );

			this._amountBtns[amount] = btn;
		}
	}
};

Opa.services.PurchaseCredits.prototype._selectAmount = function( amount ) {
	if ( this._selectedAmount && this._selectedAmount == amount ) {
		return;
	}

	for ( var i in this._amountBtns ) {
		var btn = this._amountBtns[i];
		removeClassName(btn, 'selected');
	}
	var amountBtn = this._amountBtns[amount];
	addClassName(amountBtn, 'selected');
	this._steps.method.enable();
	this._purchaseBtn.disabled = false;
	this._footer.show();
	this._steps.amount.collapse();
	if( this._steps.service ){
		this._steps.service.collapse();
	}
	this._selectedAmount = amount;
	this._updatePurchaseBtn();
	this._updateAmountRightTitle();
};

Opa.services.PurchaseCredits.prototype._getCurrencySymbol = function( currency ) {
	currency = currency || this._currentCurrency;
	if ( currency == Opa.services.CURRENCY_EUR ) {
		return '€';
	} else if ( currency == Opa.services.CURRENCY_USD ) {
		return '$';
	} else if ( currency == Opa.services.CURRENCY_GBP ) {
		return '£';
	} else {
		return '';
	}
};

Opa.services.PurchaseCredits.prototype._purchaseCredits = function() {
	var wName = 'opaServicesWindow' + Date.now();
	var w = window.open("", wName, "width=1050, height=650");
	Opa.loadingOverlay(this.node);
	var instance = this;
	var data = {
		_token: Opa.csrfToken,
		type: this._selectedMethod.type,
		price: this._selectedAmount,
		cur: this._currentCurrency,
		saveCard: false,
		drTrans: this.drTrans,
		apiTrans: this.apiTrans,
		uid: this.uid
	};

	$.ajax({
		url : Opa.baseUrl + '/postMessage/buyCreditsForm',
		dataType: 'json',
		method: 'POST',
		data: data
	}).done(function (re) {
			instance._purchaseCreditsRe(re, w, wName);
	}).fail(function(error){
		w.close();
	});
};

Opa.services.PurchaseCredits.prototype._purchaseCreditsRe = function( re, w, wName) {
	re = new Opa.services.PurchaseCredits__purchaseCreditsRe(re);
	if ( re.errorNr ) {
		// Dienas kartes limits
		if( re.errorNr == 6 ){
			Opa.removeLoadingOverlay(this.node);
			alert(Lang.get('Kartes limits sasniegts'));
			return;
		}
		alert('Bankas transakcija kļūda #' + re.errorNr);
		return;
	}

	if( re.serviceAdded ){
		this._checkAutoStatus.n = 0;
		this._checkAutoStatus( re.tid );
		return;
	}

	this.credits = re.credits;
	var node = mkE( {
		tag: 'div',
		prop: {
			innerHTML: re.form
		}
	} ).append( this.node );
	var form = node.getElementsByTagName('form')[0];
	form.target = wName;
	form.submit();
	node.remove();

	var interval = setInterval( Opa.closure( this, function() {
		if ( !w.closed ) {
			try {
				w.document;
				if ( empty(w.document.body.innerHTML) ) {
					return;
				}
			} catch (e) {
				return;
			}
		}
		clearInterval(interval);
		w.close();
		window.focus();
		this._checkAutoStatus.n = 0;
		this._checkAutoStatus( re.tid );
		this.callback();
	} ), 500 );
};
Opa.services.PurchaseCredits.prototype._checkAutoStatus = function( tid ) {
	++this._checkAutoStatus.n;
	var data = {
		_token: Opa.csrfToken,
		tid: tid,
		uid: this.uid
	};
	var instance = this;
	$.ajax({
		url : Opa.baseUrl + '/postMessage/getOrderStatus',
		dataType: 'json',
		method: 'POST',
		data: data
	}).done(function (re) {
		instance._getOrderStatusRe(tid, re);
	});
};

/**
 * @param {number} tid
 * @param {Opa.services.PurchaseCredits__getOrderStatusRe} re
 * @private
 */
Opa.services.PurchaseCredits.prototype._getOrderStatusRe = function( tid, re ) {
	re = new Opa.services.PurchaseCredits__getOrderStatusRe( re );
	if ( re.status == 'E' ) {
		Opa.removeLoadingOverlay(this.node);
		this._errorNode.style.display = '';
		this.onFailed();
		return;
	}
	if ( re.status == 'Y' ) {
		this.onBuy();
		return;
	}
	if ( this._checkAutoStatus.n > 30 ) {
		this.onClose();
		return;
	}
	setTimeout( Opa.closure( this, this._checkAutoStatus, tid ), 1000 );
};

/**
 * @param {Object} [par]
 * @constructor
 */
Opa.services.PurchaseCreditsStep__par = function( par ) {
	this.els = [];
	O2O( this, par || {} );
};
Opa.services.PurchaseCreditsStep__par.prototype.title = '';
Opa.services.PurchaseCreditsStep__par.prototype.className = '';
Opa.services.PurchaseCreditsStep__par.prototype.disabled = false;
/** @type {Array.<mkE.Base>} */
Opa.services.PurchaseCreditsStep__par.prototype.els = [];
/** @type {mkE.Base} */
Opa.services.PurchaseCreditsStep__par.prototype.rightTitle;
Opa.services.PurchaseCreditsStep__par.prototype.collapsed = false;
Opa.services.PurchaseCreditsStep__par.prototype.titleNode = '';

/**
 * @extends {mkE.Base}
 * @param {Opa.services.PurchaseCreditsStep__par|Object} [par]
 * @constructor
 */
Opa.services.PurchaseCreditsStep = function( par ) {
	par = new Opa.services.PurchaseCreditsStep__par( par );
	this._titleNode = par.titleNode || '';

	this.node = mkE( {
		tag: 'li',
		className: 'pStep',
		els: [
			{
				tag: 'h2',
				className: 'stepTitle',
				text: par.title,
				prop: {
					onclick: Opa.closure( this, this.expand )
				},
				els: [
					this._titleNode,
					this._rightTitle = mkE( {
						tag: 'p'
					} )
				]
			},
			this._content = mkE( {
				tag: 'div',
				className: 'stepContent',
				els: par.els
			} )
		]
	} );
	if ( par.rightTitle ) {
		mkE.appendTypeElement( this._rightTitle, par.rightTitle );
	}
	if ( par.className ) {
		addClassName( this.node, par.className );
	}
	if ( par.disabled ) {
		this.disable();
	}
	if ( par.collapsed ) {
		this.collapse();
	}
};
Opa.services.PurchaseCreditsStep.prototype = new mkE.Base;
Opa.services.PurchaseCreditsStep.prototype._disabled = false;
Opa.services.PurchaseCreditsStep.prototype._expanded = true;

Opa.services.PurchaseCreditsStep.prototype.disable = function(){
	if ( this._disabled ) {
		return this;
	}

	this._disabled = true;
	addClassName( this.node, 'disabled' );
	this.collapse();
	return this;
};
Opa.services.PurchaseCreditsStep.prototype.enable = function(){
	if ( !this._disabled ) {
		return this;
	}

	this._disabled = false;
	removeClassName( this.node, 'disabled' );
	this.expand();
	return this;
};
Opa.services.PurchaseCreditsStep.prototype.collapse = function(){
	if ( !this._expanded ) {
		return this;
	}

	this._expanded = false;
	this._content.style.display = 'none';
	addClassName(this.node, 'collapsed');
	return this;
};
Opa.services.PurchaseCreditsStep.prototype.expand = function(){
	if ( this._disabled || this._expanded ) {
		return this;
	}

	this._expanded = true;
	this._content.style.display = 'block';
	removeClassName(this.node, 'collapsed');
	return this;
};

/**
 * @extends {mkE.Base}
 * @constructor
 */
Opa.services.PurchaseCreditsMethods = function() {
	this.node = mkE( {
		tag: 'ul',
		className: 'methodsOptions',
		els: [
			this._cardsCont = mkE( {
				tag: 'li',
				className: 'methodGroup cards',
				style: {
					display: 'none'
				},
				els: [
					{
						tag: 'p',
						text: Lang.get('Maksājumu kartes') + ':'
					},
					this._cards = mkE( {
						tag: 'ul'
					} )
				]
			} ),
			this._banksCont = mkE( {
				tag: 'li',
				className: 'methodGroup banks',
				style: {
					display: 'none'
				},
				els: [
					{
						tag: 'p',
						text: Lang.get('Bankas') + ':'
					},
					this._banks = mkE( {
						tag: 'ul'
					} )
				]
			} ),
			this._otherCont = mkE( {
				tag: 'li',
				className: 'methodGroup other',
				style: {
					display: 'none'
				},
				els: [
					{
						tag: 'p',
						text: Lang.get('Citi') + ':'
					},
					this._other = mkE( {
						tag: 'ul'
					} )
				]
			} )
		]
	} );
};
Opa.services.PurchaseCreditsMethods.prototype = new mkE.Base;
Opa.services.PurchaseCreditsMethods.prototype._cards = null;
Opa.services.PurchaseCreditsMethods.prototype._banks = null;
Opa.services.PurchaseCreditsMethods.prototype._other = null;
Opa.services.PurchaseCreditsMethods.prototype.specType = null;

/**
 * @param {Opa.services.PurchaseCreditsMethodItem} method
 * @returns {Opa.services.PurchaseCreditsMethods}
 */
Opa.services.PurchaseCreditsMethods.prototype.addMethod = function( method ) {
	if ( method.type == 'card' ) {
		method.append( this._cards );
		this._cardsCont.style.display = '';
	} else if ( method.type == 'hanzanet'
		|| method.type == 'nordea'
		|| method.type == 'inord'
		|| method.type == 'elink'
		|| method.type == 'digilink' ) {

		method.append( this._banks );
		this._banksCont.style.display = '';
	}

	return this;
};

/**
 * @param {Object} [re]
 * @constructor
 */
Opa.services.PurchaseCredits__getDataRe = function( re ) {
	this.methods = [];
	this.rates = [];
	O2O( this, re || {} );
};
Opa.services.PurchaseCredits__getDataRe.prototype.methods = [];
Opa.services.PurchaseCredits__getDataRe.prototype.rates = [];
Opa.services.PurchaseCredits__getDataRe.prototype.currency = Opa.services.CURRENCY_EUR;
Opa.services.PurchaseCredits__getDataRe.prototype.method = Opa.services.TYPE_CARD;
Opa.services.PurchaseCredits__getDataRe.prototype.savedCard = false;
Opa.services.PurchaseCredits__getDataRe.prototype.auto = false;

/**
 * @param {Object} [par]
 * @constructor
 */
Opa.services.PurchaseCreditsMethodItem__par = function( par ) {
	this.currencies = [];
	this.amounts = {};
	O2O( this, par || {} );
};
Opa.services.PurchaseCreditsMethodItem__par.prototype.type = '';
Opa.services.PurchaseCreditsMethodItem__par.prototype.caption = '';
Opa.services.PurchaseCreditsMethodItem__par.prototype.currencies = [];
Opa.services.PurchaseCreditsMethodItem__par.prototype.amounts = {};
Opa.services.PurchaseCreditsMethodItem__par.prototype.selected = false;

/**
 * @param {Opa.services.PurchaseCreditsMethodItem} item
 */
Opa.services.PurchaseCreditsMethodItem__par.prototype.onclick = function( item ){};

Opa.services.PurchaseCreditsMethodItem = function( par ) {
	par = new Opa.services.PurchaseCreditsMethodItem__par(par);
	this.type = par.type;
	this.caption = par.caption;
	this.currencies = par.currencies;
	this.amounts = par.amounts;
	this.specType = par.specType;
	if ( par.onclick ) {
		this.onclick = par.onclick;
	}

	var p = {};
	p.icon = {
		tag: 'img',
		className: 'methodIcon ' + this.type,
		src: '/img/pm/'+ this.type +'.png',
		prop: {
			alt: this.caption
		}
	};
	p.checked = par.selected;
	p.name = 's[pm]';
	p.onclick = Opa.closure( this, this._onclick );

	this.node = mkE( {
		tag: 'li',
		className: 'methodItem',
		els: [
			new Opa.radio(p)
		]
	} );
};
Opa.services.PurchaseCreditsMethodItem.prototype = new mkE.Base;
Opa.services.PurchaseCreditsMethodItem.prototype.type = '';
Opa.services.PurchaseCreditsMethodItem.prototype.caption = '';
Opa.services.PurchaseCreditsMethodItem.prototype.currencies = [];
Opa.services.PurchaseCreditsMethodItem.prototype.amounts = {};

/**
 * @param {Opa.services.PurchaseCreditsMethodItem} item
 */
Opa.services.PurchaseCreditsMethodItem.prototype.onclick = function( item ) {};
Opa.services.PurchaseCreditsMethodItem.prototype._onclick = function() {
	this.onclick( this );
};

/**
 * @param {Object} [re]
 * @constructor
 */
Opa.services.PurchaseCredits__purchaseCreditsRe = function( re ) {
	O2O( this, re || {} );
};
Opa.services.PurchaseCredits__purchaseCreditsRe.errorNr = 0;
Opa.services.PurchaseCredits__purchaseCreditsRe.tid = 0;
Opa.services.PurchaseCredits__purchaseCreditsRe.chargeAuto = false;
Opa.services.PurchaseCredits__purchaseCreditsRe.form = '';
Opa.services.PurchaseCredits__purchaseCreditsRe.price = 0;
Opa.services.PurchaseCredits__purchaseCreditsRe.credits = 0;

Opa.services.PurchaseCredits__getOrderStatusRe = function( re ) {
	O2O( this, re || {} );
};
Opa.services.PurchaseCredits__getOrderStatusRe.prototype.status = '';