Games = {
	showUpdates: function(id, page){
		$.get( Opa.baseUrl + '/games/updates/'+id+'?page='+page, function( data ) {
			Opa.modal.modal();
			Opa.modalContent.html(data);
			Opa.hideModal();
		});
	},

	getUpdatePage: function(id, page) {
		$.ajax({
			url : Opa.baseUrl + '/games/updates/'+id+'?page=' + page,
			dataType: 'json'
		}).done(function (data) {
			Opa.modal.removeData('bs.modal');
			Opa.modal.find('.modal-body').data('');
			Opa.modal.find('.load_modal').html(data);
		}).fail(function (error) {
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
		});
	},

	keepAlive:function (appId) {
		window.setInterval(function(){Games.updatePage(appId)}, 90000);
	},

	updatePage:function(appId){
		$.ajax({
			url : Opa.baseUrl + '/games/keepalive/'+appId,
			method: 'GET',
			dataType: 'json',
		}).done(function (data) {
		}).fail(function (error) {
			Opa.console.log('Kļūda keepalive');
		});
	},

	showSupportModal: function (id) {
        Lang.setLocale(Opa.lang);

        var exists = document.getElementById('supportModal');
        if (exists) {
            exists.remove();
        }
		var modalBody = mkE({
			tag: 'div',
			className: 'modal-body',
			els: [
				{
					tag: 'div',
					className: 'alert alert-danger hidden',
					id:'modal-alert'
				},
				{
					tag: 'form',
					id: 'supportMsg',
					els: [
						{
							tag: 'div',
							className: 'form-group',
							els: [
								{
									tag: 'label',
									text: Lang.get('games.supportCategory')
								},
								{
									tag: 'select',
									className: 'form-control',
									name: 'category',
									els: [
										{tag: 'option', value: '1', text: Lang.get('games.bug')},
										{tag: 'option', value: '2', text: Lang.get('games.performanceIssue')},
										{tag: 'option', value: '3', text: Lang.get('games.recommendation')}
									]
								}
							]
						},
						{
							tag: 'div',
							className: 'form-group',
							els: [
								{
									tag: 'label',
									text: Lang.get('games.problemDescription')
								},
								{
									tag: 'textarea',
									attr:{
										name:'problem'
									},
									className: 'form-control',
									style: {
										resize: 'none'
									}
								}
							]
						},
						{
							tag: 'div',
							className: 'modal-footer',
							els: [
								{
									tag: 'button',
									className: 'btn btn-default',
									text: Lang.get('games.closeModal'),
									attr: {
										'data-dismiss': 'modal'
									}
								},
								{
									tag: 'button',
									text: Lang.get('games.send'),
									className: 'btn btn-dr-orange pull-right',
									attr: {
										onclick: 'Games.sendSupport('+id+'); return false;'
									}
								}
							]
						},
					]
				}
			]
		});

		var content = mkE({
			tag: 'div',
			className: 'modal fade',
			id: 'supportModal',
			els: [{
				tag: 'div',
				className: 'modal-dialog',
				els: [
					{
						tag: 'div',
						className: "modal-content",
						els: [
							mkE({
								tag: 'div',
								className: 'modal-header text-center',
								els: [
									{
										tag: 'button',
										className: 'close',
										innerHTML: '&times;',
										attr: {type: 'button', 'data-dismiss': 'modal'}
									},
									{
										tag: 'img',
										className:'opaModalLogo',
										src: '/img/opa_logo_modal.png'
									},
									{tag: 'h3', className: '', text: Lang.get('games.supportMessage')}
								]
							}),
							modalBody
						]
					}
				]
			}]
		});

		$('body').append(content);
        $('#supportModal').modal();
	},

	sendSupport: function(id) {
		var data = $("#supportMsg").serializeArray();
		data.push({name:'id', value:id});
		data.push({ name: "_token", value: Opa.csrfToken});
		$.ajax({
			url: Opa.baseUrl + '/games/bugreport',
			method: 'POST',
			dataType: 'json',
			data: data
		}).done(function (data) {
			if (data.status == 'error') {
				$('#modal-alert').text(data.message).removeClass('hidden').show().delay(1000).fadeOut(1000);
			} else {
				$('#supportModal').modal('hide');
				Opa.showMessage(data.message, 'success');
			}
		}).fail(function (error) {
			$('#modal-alert').text(Lang.get('games.bug')).removeClass('hidden').show().delay(1000).fadeOut(1000);
		});
	},

	getOnlineUsers: function(id, page){
		$.ajax({
			method: 'GET',
			url : Opa.baseUrl + '/games/online/'+id+'?page=' + page,
			dataType: 'json'
		}).done(function (data) {
			$('#'+id).html(data);
			Games.onlineUsersPagination(id);
		}).fail(function (error) {
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
		});
	},

	onlineUsersPagination: function(id){
		$('#'+id+' .pagination a').each(function(e){
			var page =  $(this).attr('href').split('page=')[1];
			$(this).attr('data',$(this).attr('href'));
			$(this).attr('href','javascript:');
			$(this).bind('click',function(){
				Games.getOnlineUsers(id, page);
			});
		});
	},

	sendMail: function(fid, topic, text, callback) {
		$.ajax({
			method: 'GET',
			url : Opa.baseUrl + '/friends/name/'+fid,
			dataType: 'json'
		}).done(function (data) {
			Games._sendMail(data, fid, topic, text, callback);
		}).fail(function (error) {
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
		});
	},

	_sendMail: function(friendName, fid, topic, text, callback) {
		Lang.setLocale(Opa.lang);
		var modal = new Modal( {
			width:720,
			withoutPadding:false,
			title: Lang.get('games.newMail'),
			overlayClose:false,
			onClose:Opa.closure( this, function(){} )
		});
		var _newMailForm = mkE({
			tag: 'form',
			id: 'postMessageMail',
			els:[
				{
					tag: 'div',
					className: 'form-group',
					els: [
						{
							tag: 'label',
							text: Lang.get('games.receiver')
						},
						{
							tag: 'input',
							className: 'form-control',
							attr: {
								disabled: true
							},
							value: friendName
						}
					]
				},
				{
					tag: 'div',
					className: 'form-group',
					els: [
						{
							tag: 'label',
							text: Lang.get('games.topic')
						},
						{
							tag: 'input',
							className: 'form-control',
							value: topic,
							attr:{
								name: 'topic'
							}
						}
					]
				},
				{
					tag: 'div',
					className: 'form-group',
					els: [
						{
							tag: 'textarea',
							className: 'form-control',
							text: text,
							attr:{
								name: 'text'
							}
						}
					]
				},
				{
					tag: 'hr',
				},
				{
					tag: 'button',
					type: 'submit',
					className: 'btn btn-primary pull-right',
					text: Lang.get('games.send'),
					prop:{
						onclick: Opa.closure(this, this.sendEmail, fid, callback)
					}
				}
			]
		});
		modal.els( [ _newMailForm ] );
	},

	sendEmail: function(fid, callback){
		var data = $("#postMessageMail").serializeArray();
		data.push({name:'id', value:fid});
		data.push({ name: "_token", value: Opa.csrfToken});
		$.ajax({
			url: Opa.baseUrl + '/postMessage/mail',
			method: 'POST',
			dataType: 'json',
			data: data
		}).done(function (data) {
			Opa.console.log('status:'+data.status + ' message:'+data.message);
			callback();
		}).fail(function (error) {
			Opa.console.log('Failed sending e-mail', 'danger', '', '', error);
		});
	},

	refuseApp: function(appId) {
		$.ajax({
			url: Opa.baseUrl + '/games/refuse/'+appId,
			method: 'GET',
			dataType: 'json',
		}).done(function (data) {
			if (data.status == 'error') {
				Opa.showMessage(data.message, 'danger');
			} else {
				window.location.replace(Opa.baseUrl + '/games/view/'+appId);
			}
		}).fail(function (error) {
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', 401);
		});
	}
};
