Profile = {
	timeout: null,

	showFriends: function(uid, tab){
		tab = typeof tab !== 'undefined' ? tab : 1;
		var url = Opa.baseUrl + '/friends/'+uid+'/'+tab;
		$.get( url, function( data ) {
			if (data.status && data.status == 'list') {
				window.location.href = Opa.baseUrl+'/friends/'+uid;
			} else {
				Opa.modal.modal();
				Opa.modalContent.html(data);
				Opa.hideModal();
			}
		}).fail(function(error){
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
		});
	},

	changeFriendTab: function(tab, uid){
		$.get( Opa.baseUrl + '/friends/'+uid+'/'+tab, function( data ) {
			Opa.modal.find(Opa.modalClass).html(data);
		});
	},

	getFriendsPage: function(page, tab, uid){
		$.ajax({
			url : Opa.baseUrl + '/friends/'+uid+'/'+tab+'/?page=' + page,
			dataType: 'json'
		}).done(function (data) {
			Opa.modal.removeData('bs.modal');
			Opa.modal.find('.modal-body').data('');
			Opa.modal.find('.load_modal').html(data);
		}).fail(function (error) {
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
		});
	},

	invite: function(friend, button, name, avatar, block){
		$.ajax({
			url: Opa.baseUrl + '/invite',
			method: 'POST',
			dataType: 'json',
			data: {friend: friend, _token: Opa.csrfToken, name:name, avatar:encodeURIComponent(avatar)}
		}).done(function (data) {

			if (data.status === 'success') {
				Opa.showMessage(data.message, 'success', button, 'invite.inviteSent');
				if (!Opa.modal.hasClass('in')) {
					clearTimeout(Profile.timeout);
					if (block == 'pymk') {
						Profile.timeout = setTimeout(function(){Profile.refreshBlock('pymkFriendsBlock');}, 2000);
					} else {
						Profile.timeout = setTimeout(function(){Profile.refreshBlock('providerFriendsBlock');}, 2000);
					}
				}
			} else {
				Opa.showMessage(data.message, 'danger', button, 'invite.inviteSent');
			}
		}).fail(function (error) {
			Opa.showMessage('Kļūda nosūtot ielūgumu. Lūdzu pārlādējiet lapu', 'danger', button, '', error);
		});
	},

	refreshBlock: function(blockId){
		switch (blockId){
			case 'providerFriendsBlock':
				$.get( Opa.baseUrl + '/friends/providerFriends/', function( data ) {
					document.getElementById(blockId).innerHTML = data;
				});
				break;
			case 'pymkFriendsBlock':
				$.get( Opa.baseUrl + '/friends/pymkFriends/', function( data ) {
					document.getElementById(blockId).innerHTML = data;
				});
				break;
		}
	},

	confirmInvite: function(code, button) {
		$.ajax({
			url: Opa.baseUrl + '/invite/accept',
			method: 'GET',
			dataType: 'json',
			data: {code: code, _token: Opa.csrfToken}
		}).done(function (data) {
			if (data.status === 'success') {
				Opa.showMessage(data.message, 'success', button, 'invite.invitationConfirmed');
				if (!Opa.modal.hasClass('in')) {
					Profile.refreshAfterInvite();
				}
			} else {
				Opa.showMessage(data.message, 'danger', button, 'invite.invitationConfirmed');
			}
		}).fail(function (error) {
			Opa.showMessage('Kļūda apstiprinot ielūgumu. Lūdzu pārlādējiet lapu', 'danger', button, '', error);
		});
	},

	refreshAfterInvite: function() {
		$.get( Opa.baseUrl + '/profile/confirmInviteRe', function( data ) {
			document.getElementById('profileFriendsInvitesBlock').innerHTML = data.invites;
			document.getElementById('profileFriendsBlock').innerHTML = data.friends;
		});
	},

	interceptFriendship: function(uid, fid, invite, button){
		$.ajax({
			url: Opa.baseUrl + '/invite/intercept',
			method: 'POST',
			dataType: 'json',
			data: {uid: uid, fid:fid, invite:invite, _token: Opa.csrfToken}
		}).done(function (data) {
			if (data.status == 'success') {
				Opa.showMessage(data.message, 'success', button, 'profile.friendshipStopped');
			} else {
				Opa.showMessage(data.message, 'danger', button, 'profile.friendshipStopped');
			}
		}).fail(function (error) {
			Opa.showMessage('Kļūda pārtraucot draudzību. Lūdzu pārlādējiet lapu', 'danger', button, '', error);
		});
	},

	reloadSocialAccounts: function(){
		$.ajax({
			url: Opa.baseUrl + '/profile/socAccounts',
			method: 'GET',
			dataType: 'json',
		}).done(function (data) {
			$('#socialAccountsSection').html(data);
			$('[data-toggle="popover"]').popover({delay: { "show": 100, "hide": 1000 }});
		}).fail(function (error) {
			Opa.showMessage('Kļūda pievienojot sociālo kontu.', 'danger', '', '', error);
		});
	},

	sendInvitation: function(){
		var data = $("#inviteForm").serializeArray();
		data.push({ name: "_token", value: Opa.csrfToken});
		$.ajax({
			url: Opa.baseUrl + '/profile/sendInvite',
			method: 'POST',
			dataType: 'json',
			data: data
		}).done(function (data) {
			if (data.status == 'error') {
				$('#inviteMessage').text(data.message).removeClass('hidden').show().delay(1000).fadeOut(1000);
			} else {
				InfoBox.close();
				Opa.showMessage(data.message, 'success');
			}
		}).fail(function (error) {
			$('#inviteMessage').text(Lang.get('layout.inviteBug')).removeClass('hidden').show().delay(1000).fadeOut(1000);
		});
	},

	disconnectProvider: function(provider) {
		$.ajax({
			url: Opa.baseUrl + '/profile/disconnectAccount',
			method: 'GET',
			dataType: 'json',
			data:{provider:provider}
		}).done(function (data) {
			if (data.status == 'success') {
				Profile.reloadSocialAccounts();
			} else {
				Opa.showMessage(data.message, 'danger');
			}
		}).fail(function (error) {
			Opa.showMessage('Kļūda atvienojot sociālo kontu.', 'danger', '', '', error);
		});
	},

	search: function(tab, term, page) {
        $.get(Opa.baseUrl + "/friends/search/" + tab + '?key=' + encodeURIComponent(term) + '&page=' +page, function (data) {
            if (data.status == 'success') {
                var body = $('#friendsModalBody');
                body.html("");
                body.html(data.view);
            } else {
                Opa.showMessage(data.message, 'danger');
            }
        }).fail(function (error) {
            Opa.showMessage('Kļūda meklējot!', 'danger', '', '', error);
        });
    },

	startSearch: function(e, tab){
		if (e.keyCode == 13) {
			Profile.search(tab, $('#searchInput').val());
		}
	}
};