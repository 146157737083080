
/**
 * Construct an mkE element
 * @param {mkE.Par} par
 * @returns {*}
 */
function mkE(par) {
	if (!par.tag && typeof par.text != 'string') {
		return false;
	}
	if (par.tag) {
		par.prop = par.prop || {};
		switch (par.tag) {
			case 'img':
				if (par.src) {
					par.prop.src = par.src;
				}
				break;
			case 'a':
				par.prop.href = par.prop.href || par.href || 'javascript:';
				if (par.target) {
					par.prop.target = par.target;
				}
				break;
			case 'select':
				if (typeof par.value != 'undefined') {
					par.prop.value = par.value;
				}
				if (par.name) {
					par.prop.name = par.name;
				}
				break;
			case 'option':
				if (par.value) {
					par.prop.value = par.value;
				}
				break;
			case 'input':
				if (par.value) {
					par.prop.value = par.value;
				}
				if (par.type) {
					par.prop.type = par.type;
				}
				if (par.name) {
					par.prop.name = par.name;
				}
				if (par.checked) {
					par.prop.checked = par.checked;
				}
				if (par.type && par.type == 'checkbox' && par.prop.checked) {
					par.prop.defaultChecked = par.prop.checked;
				}
				break;
			case 'textarea':
				if (par.value) {
					par.prop.value = par.value;
				}
				break;
			case 'iframe':
				if (par.src) {
					par.prop.src = par.src;
				}
				break;
		}
		if (par.id) {
			par.prop.id = par.id;
		}
		if (par.className) {
			par.prop.className = par.className;
		}
		if (par.style) {
			par.prop.style = par.style;
		}
		if (par.innerHTML) {
			par.prop.innerHTML = par.innerHTML;
		}
		var _tag = par.tag;
		var elm;
		if (par.tag == _tag) {
			elm = document.createElement(par.tag);
		} else {
			try {
				elm = document.createElement(par.tag);
			} catch (e) {
				elm = document.createElement(_tag);
			}
		}
		if (par.attr) {
			for (var key in par.attr) {
				elm.setAttribute(key, par.attr[key]);
			}
		}

		if (par.els && typeof par.els == 'object') {
			var r;
			for (var ii in par.els) {
				r = par.els[ii];
				switch (typeof r) {
					case 'number':
						elm.appendChild(document.createTextNode(String(r)));
						continue;
					case 'string':
						elm.appendChild(document.createTextNode(r));
						continue;
					case 'object':
						if (!r.nodeType && r.tag) {
							r = mkE(r);
							break;
						}
						if (r instanceof Array) {
							for (var i = 0; i < r.length; ++i) {
								mkE._appendTypeElement(elm, r[i]);
							}
							continue;
						}
						break;
					case 'boolean':
						continue;
					case 'undefined':
						if (console && console.error) {
							console.error('mkE: undefined child', par);
						}
						continue;
				}
				if (r.append) {
					r.append(elm);
				} else {
					elm.appendChild(r);
				}
			}
		}
		if (par.els && typeof par.els == 'string') {
			par.innerHTML = par.els;
		}
		mkE.O2O(elm, par.prop);
		if (typeof par.text !== 'undefined') {
			elm.appendChild(document.createTextNode(par.text));
		}
		elm.append = mkE.append;
		elm.appendTo = mkE.appendTo;
		elm.prepend = mkE.prepend;
		elm.remove = mkE.remove;
		elm.replace = mkE.replace;
	} else {
		elm = document.createTextNode(par.text);
	}
	return elm;
}

mkE.append = function (node) {
	if (typeof node == 'string') {
		node = document.getElementById(node);
	}
	node.appendChild(this);
	return this;
};

mkE.prepend = function (node) {
	if (node.firstChild) {
		mkE.insertBefore(this, node.firstChild);
		return this;
	}
	node.appendChild(this);
	return this;
};

mkE.remove = function () {
	mkE.removeNode(this);
	return this;
};

mkE.clear = function () {
	mkE.clearNode(this);
	return this;
};

mkE._appendTypeElement = function (elm, r) {
	switch (typeof r) {
		case 'number':
			elm.appendChild(document.createTextNode(String(r)));
			return;
		case 'string':
			elm.appendChild(document.createTextNode(r));
			return;
		case 'object':
			if (!r.nodeType && r.tag) {
				r = mkE(r);
				break;
			}
			if (r instanceof Array) {
				for (var i = 0; i < r.length; ++i) {
					mkE._appendTypeElement(elm, r[i]);
				}
				return;
			}
			break;
		case 'boolean':
			return;
		case 'undefined':
			if (console && console.error) {
				console.error('mkE: undefined child', par);
			}
			return;
	}
	if (r.append) {
		r.append(elm);
	} else {
		elm.appendChild(r);
	}
};

mkE.appendTo = function (parent, jquery) {
	if (typeof jquery === 'undefined') {
		jquery = true;
	}
	if (typeof parent == 'string') {
		if (jquery) {
			parent = $(parent)[0];
		} else {
			parent = document.getElementById(parent);
		}
	}
	if (parent instanceof Array) {
		parent = mkE.reset(parent);
	}
	parent.appendChild(this);
	return this;
};

mkE.replace = function (node) {
	if (!node.parentNode) {
		return this;
	}
	mkE.insertBefore(this, node);
	mkE.removeNode(node);
	return this;
};

mkE.removeNode = function (node) {
	if (node.parentNode && node.parentNode.tagName) {
		return node.parentNode.removeChild(node);
	}
	return false;
};

mkE.clearNode = function (node) {
	var n;
	while (n = node.firstChild) {
		if (n.remove) {
			n.remove();
			continue;
		}
		mkE.removeNode(n);
	}
	return node;
};

mkE.O2O = function (target, source) {
	if (typeof( target ) == 'undefined' || typeof( source ) == 'undefined') {
		return;
	}
	for (var key in source) {
		var sourceValue = source[key];
		if (typeof sourceValue == 'undefined') {
			if (console && console.error) {
				console.error('mkE O2O undefined key', source, key);
			}
		}
		if (sourceValue && sourceValue.constructor == Object) {
			if (!target[key]) {
				target[key] = {};
			}
			mkE.O2O(target[key], sourceValue);
		} else {
			try {
				target[key] = sourceValue;
			} catch (e) {
				// Somethings bad
			}
		}
	}
};

/**
 * Get first value of an array
 * @param {Array} v
 * @returns {*}
 */
mkE.reset = function (v) {
	for (var k in v) {
		return v[k];
	}
	return null;
};

mkE.insertBefore = function (el, before) {
	before.parentNode.insertBefore(el, before);
};

/**
 * @param {Node} elm
 * @param {mkEl} r
 */
mkE.appendTypeElement = function( elm, r ){
	if( r instanceof Function ){
		r = r();
	}
	switch( typeof r ){
		case 'number':
			elm.appendChild( document.createTextNode( String(r) ) );
			return;
		case 'string':
			elm.appendChild( document.createTextNode(r) );
			return;
		case 'object':
			if(!r){ // null
				return;
			}
			if( !r.nodeType && ( r.tag || r.text ) ){
				r = mkE(r);
				break;
			}
			if( r instanceof Array ){
				for( var i = 0; i < r.length; ++ i ){
					mkE.appendTypeElement( elm, r[i] );
				}
				return;
			}
			break;
		case 'boolean':
			return;
		case 'undefined':
			Opa.console.error( 'Undefined child', par );
			return;
	}
	if( r.append ){
		r.append(elm);
	} else {
		elm.appendChild(r);
	}
};

/**
 * Constructor for documentation, not for using
 * @constructor
 */
mkE.Par = function () {
};
mkE.Par.prototype.tag = '';
mkE.Par.prototype.text = '';
mkE.Par.prototype.prop = {};
mkE.Par.prototype.target = '';
mkE.Par.prototype.href = '';
mkE.Par.prototype.value = '';
mkE.Par.prototype.name = '';
mkE.Par.prototype.type = '';
mkE.Par.prototype.checked = false;
mkE.Par.prototype.src = '';
mkE.Par.prototype.attr = {};
mkE.Par.prototype.id = '';
mkE.Par.prototype.className = '';
mkE.Par.prototype.style = '';
mkE.Par.prototype.innerHTML = '';
mkE.Par.prototype.els = [];


/**
 * @param {Node} node
 * @param {string} className
 * @returns {Node}
 */
function addClassName( node, className ){
	if( node.classList ){
		var cl = className.split(' ');
		for( var k in cl ) {
			if( cl[k] ){
				node.classList.add(cl[k]);
			}
		}
		return node;
	}
	var cn = node.className.split(' ');
	var is = false;
	var i;
	for( i = 0; i < cn.length; i ++ ){
		if( cn[ i ] == className ){
			is = true;
		}
	}
	if(!is){
		cn.push( className );
	}
	node.className = cn.join( ' ' );
	return node;
};

/**
 * @param {Node} node
 * @param {string} className
 * @returns {Node}
 */
function removeClassName( node, className ){
	if( node.classList ){
		var cl = className.split(' ');
		for( var k in cl ) {
			if( cl[k] ){
				node.classList.remove(cl[k]);
			}
		}
		return node;
	}
	var cn = node.className.split(' ');
	var cnr = [];
	var found = false;
	for( var i = 0; i < cn.length; i ++ ){
		if( cn[ i ] === className ){
			found = true;
		} else {
			cnr.push( cn[ i ] );
		}
	}
	if(found){
		node.className = cnr.join( ' ' );
	}
	return node;
};

function clearNode( node ){
	var n;
	while( n = node.firstChild ){
		removeNode(n);
	}
	return node;
};

function removeNode(node){
	if( node.parentNode && node.parentNode.tagName ){
		return node.parentNode.removeChild( node );
	}
	return false;
};

function evalScriptHTML( html, $this ){
	var np, p = 0;
	while( ( np = html.indexOf( '<script', p ) ) != -1 ){
		p = html.indexOf( '>', np );
		np = html.indexOf( '</script>', p );
		if( np == -1 ){
			continue;
		}
		try{
			var text = html.substr( p + 1, np - p -1 );
			if($this){
				var fn = new Function(text);
				fn.call( $this || window );
			} else {
				eval.call( window, text );
			}
		} catch(e){
			Opa.console.error(e);
		}
	}
};

function array_search( need, haystack, strict ){
	var i;
	if( haystack instanceof Array && typeof haystack.indexOf == 'function' && ! strict ){
		i = haystack.indexOf( need );
		if( i != -1 ){
			return i;
		}
	} else if( typeof haystack == 'object' || typeof haystack == 'function' ){
		for( i in haystack ){
			if( array_search ? haystack[ i ] === need : haystack[ i ] == need ){
				return i;
			}
		}
	}
	return false;
};

function reset( v ){
	for( var k in v ){
		return v[ k ];
	}
	return null;
};

/**
 * @param {*} v
 * @returns {boolean}
 */
function isset(v){
	if( arguments.length > 1 ){
		for( var i = 0; i < arguments.length; ++ i ){
			if( !isset( arguments[i] ) ){
				return false;
			}
		}
		return true;
	}
	if( v === null ){
		return false;
	}
	if( typeof v == 'undefined' ){
		return false;
	}
	return true;
}

function setNodeText( node, text ) {
	if (typeof node.textContent == 'string') {
		node.textContent = text;
	} else if (typeof node.innerText == 'string') {
		node.innerText = text;
	} else {
		while (node.firstChild) {
			node.removeChild(node.firstChild);
		}
		node.appendChild( node.createTextNode(text) );
	}
}

function nextElementSibling( node ) {
	if ( node.nextElementSibling ) {
		return node.nextElementSibling;
	}

	do {
		node = node.nextSibling;
	} while ( node && node.nodeType != Node.ELEMENT_NODE );

	return node;
}

function empty( v ){
	if( v === null ) return true;
	switch( typeof v ){
		case 'object':
			if( typeof v.empty == 'function' ){
				return v.empty();
			}
			for( var k in v ) return false;
			break;
		case 'boolean':
			return ! v;
		case 'number':
			return ( v === 0 );
		case 'string':
			return ( v === '' || v === '0' );
		case  'function':
			return false;
	}
	return true;
};

function O2O( obj1, obj2 ){
	if( typeof obj1 == 'undefined' ){
		return;
	}
	if( typeof obj2 == 'undefined' ){
		return;
	}
	for( var key in obj2 ){
		var v = obj2[key];
		if( typeof v == 'undefined' ){
			D.console.error( obj2, key );
		}
		if( v && v.constructor == Object ){
			if( !obj1[key] ){
				obj1[key] = {};
			}
			O2O( obj1[key], v );
		} else {
			try{
				obj1[key] = v;
			} catch(e){
				D.console.error(e);
			}
		}
	}
};

function count( v ){
	if( typeof v != 'object' ){
		return 1;
	}
	if( v instanceof Array ){
		return v.length;
	}
	if( typeof v.length == 'function' ){
		return v.length();
	}
	var l = 0;
	for( var k in v ) l ++;
	return l;
}

function getStyle(el, prop){
	if( document.defaultView && document.defaultView.getComputedStyle ){
		return document.defaultView.getComputedStyle( el, null )[ prop ];
	} else if ( el.currentStyle ){
		return el.currentStyle[ prop] ;
	} else {
		return el.style[ prop ];
	}
}

Opa.insertBefore = function( el, before ){
	before.parentNode.insertBefore( el, before );
};

Opa.insertAfter = function( el, node ){
	if( node.nextSibling ){
		Opa.insertBefore( el, node.nextSibling );
	} else {
		node.parentNode.appendChild( el );
	}
};
