/**
 * Copy of D.modal for iframe post messages.
 * @param par
 */
var Modal = function(par){
	par = par || {};
	this.width = par.width || 600;
	this.height = par.height || 'auto'; // tikai iframe, ja var gadīties, ka ver vaļā citu domēnu
	this.iframeHeightId = par.iframeHeightId || 'outermost';
	this.overlayClose = ( typeof par.overlayClose == 'undefined' ? true : par.overlayClose );
	this.withoutOverlay = par.withoutOverlay || false;
	this.loadingText = par.loadingText || '';
	this.onload = par.onload || function(){};
	this.onClose = par.onClose || function(){};
	this.onBeforeClose = par.onBeforeClose || function(){};
	this.title = par.title || '';
	this.zIndex = ++InfoBox.zIndex;
	this.parent = par.parent || document.body;
	this.href = par.href || '';
	this.overlay = mkE( {
		tag:'div',
		className:'InfoBoxOverlay',
		style:{
			zIndex:this.zIndex
		}
	} );
	if( par.backgroundColor ){
		this.overlay.style.backgroundColor = par.backgroundColor;
	}
	this.node = mkE( {
		tag:'div',
		className:'InfoBox',
		prop:{
			onclick:Opa.closure( this, this._overlayOnclick )
		},
		style:{
			zIndex:this.zIndex
		},
		els:[
			this.borderNode = mkE( {
				tag:'span',
				className:'radius3 InfoBoxBorder',
				prop:{
					onclick:function(e){
						e.stopPropagation();
					}
				},
				els:[
					this.titleNode = mkE( {
						tag:'div',
						className:'InfoBoxTitle',
						els:[
							{
								tag:'span',
								className:'cut',
								els:[
									this.titleTextNode = mkE( {
										text:''
									} )
								]
							},
							this.closeButtonNode = mkE( {
								tag:'a',
								className:'InfoBoxClose',
								prop:{
									onclick:Opa.closure( this, this.close )
								}
							} )
						]
					} ),
					this.contentWrapNode = mkE( {
						tag:'div',
						className:'InfoBoxContentWrap',
						els:[
							this.content = mkE( {
								tag:'div',
								className:'radius3 InfoBoxContent'
							} )
						]
					} )
				]
			} )
		]
	} );

	if( this.parent !== document.body ){
		this.overlay.style.zIndex = '';
		this.overlay.style.position = 'absolute';
		this.node.style.zIndex = '';
		this.node.style.position = 'absolute';
		this.node.style.overflow = 'auto';
	}
	if( par.withoutPadding ){
		addClassName( this.contentWrapNode, 'InfoBoxNoPadding' );
	}
	if( par.className ){
		addClassName( this.node, par.className );
		addClassName( this.overlay, par.className );
	}
};

Modal.c = 0;
/** @type {Array.<Modal>} */
Modal._inst = [];
Modal.resizeOnClose = false;

Modal.closeLast = function(){
	if( !this._inst.length ){
		return false;
	}
	this._inst[ this._inst.length - 1 ].close();
	return true;
};

Modal.count = function(){
	return this._inst.length;
};

Modal.prototype.opened = false;

Modal.prototype._show = function(){
	clearNode( this.content );
	if( this.opened ){
		return;
	}
	if( this.parent === document.body && ++ Modal.c == 1 ){
		InfoBox.opened = true;
		Modal.hideBodyScroll();
		Modal._bindEventsGlobal();
	}
	Modal._inst.push(this);
	this.opened = true;
	this.overlay.append( this.parent );
	if( !this.withoutOverlay ){
		setTimeout( Opa.closure( this, function(){
			if ( !this.ready && this.loadingText ) {
				mkE({
					tag: 'div',
					className: 'loadingTextWidth'
				} ).append( this.content );
				mkE({
					tag: 'div',
					className: 'loadingText',
					text: this.loadingText
				} ).append( this.content );
			}
		}), 2000 );
		this.node.append( this.parent );
	}
	this._bindEvents();
	setTimeout( Opa.closure( this, function(){
		addClassName( this.node, 'InfoBoxAnimated' );
		addClassName( this.overlay, 'InfoBoxAnimated' );
	} ), 1 );
};

Modal.prototype._bindEvents = function(){
	this._resizeWin = Opa.closure( this, this._resize );
	$(window).bind( 'resize', this._resizeWin );
	this._resizeTO = setInterval( Opa.closure( this, this._resize ), 100 );
	this._resize();
};

Modal.prototype._unbindEvents = function(){
	$(window).unbind( 'resize', this._resizeWin );
	clearTimeout( this._resizeTO );
};

Modal.prototype._resize = function(){
	if( this.type == 'img' ){
		this.content.style.width = 'auto';
	} else if( parseInt( this.content.style.width ) ){
		this.content.style.width = this.width + 'px';
	}
	if( this.type == 'iframe' && this.iframeNode && this.ready ){
		try{
			var h = this.iframeNode.contentWindow.document.getElementById( this.iframeHeightId ).offsetHeight;
			this.iframeNode.style.height = h + 'px';
		} catch(e){
			if( parseInt( this.height ) ){
				this.iframeNode.style.height = this.height + 'px';
			}
		}
	}
	var wh = $(window).height();
	var marginTop = Math.max( 20, wh - this.borderNode.offsetHeight ) / 2;
	var mt = parseInt( this.borderNode.style.top );
	this.borderNode.style.top = '20px';
};

Modal.prototype._onLoad = function(){
	this.ready = true;
	addClassName( this.node, 'InfoBoxReady' );
	addClassName( this.overlay, 'InfoBoxReady' );
	if( parseInt( this.width ) ){
		this.content.style.width = this.width + 'px';
	}
	this._resize();
	setTimeout( Opa.closure( this, function(){
		addClassName( this.borderNode, 'InfoBoxBorderAnimated' );
	} ), 1 );
	this.setTitle( this.title );
	this.onload();
};

Modal.prototype.open = function( url, rqPar ){
	rqPar = rqPar || {};
	this.type = 'ajax';
	this._show();
	rqPar.withoutLoading = true;
	if( typeof rqPar.hash == 'undefined' ){
		rqPar.hash = false;
	}
	var onload = rqPar.onload;
	rqPar.onload = Opa.closure( this, function(html){
		this._onLoad();
		if(onload){
			onload(html);
		}
	} );
	Opa.rq( url, this.content, rqPar );
};

Modal.prototype.iframe = function(url){
	this.type = 'iframe';
	this._show();
	this.iframeNode = mkE( {
		tag:'iframe',
		className:'radius3 InfoBoxIframe',
		prop:{
			frameBorder:0
		}
	} ).append( this.content );
	if( InfoBox._i === this ){
		InfoBox._.iframe = InfoBox._i.iframeNode;
	}

	$( this.iframeNode ).bind( 'load', Opa.closure( this, this._iframeOnload ) );
	this.iframeNode.src = url;
};

Modal.prototype._iframeOnload = function(){
	this._onLoad();
};

Modal.prototype.img = function(src){
	addClassName( this.node, 'InfoBoxImg' );
	this.type = 'img';
	this._show();
	this._imgNode = mkE( {
		tag:'img',
		prop:{
			onload:Opa.closure( this, this._imgOnload )
		},
		style:{
			display:'block',
			maxHeight:( $(window).height() - 60 ) + 'px',
			maxWidth:( Math.max(980, $(window).width()) - 60 ) + 'px'
		}
	} );
	if( this.href ){
		mkE( {
			tag:'a',
			href:this.href,
			els:[
				this._imgNode
			]
		} ).append( this.content );
	} else {
		this._imgNode.append( this.content );
	}
	this._imgNode.src = src;

	this._imgNode.oncontextmenu = function(){
		return false;
	};
};

Modal.prototype._imgOnload = function(){
	this._onLoad();
};

Modal.prototype.html = function(html){
	this.type = 'html';
	this._show();
	this.content.innerHTML = html || '';
	this._onLoad();
};

Modal.prototype.els = function(els){
	this.type = 'html';
	this._show();
	mkE._appendTypeElement( this.content, els );
	this._onLoad();
};

Modal.prototype.close = function(){
	if( !this.opened ){
		return false;
	}
	if( this.parent === document.body && -- Modal.c == 0 ){
		Modal.showBodyScroll();
		Modal._unbindEventsGlobal();
		InfoBox.opened = false;
	}
	this.opened = false;
	var k = array_search( this, Modal._inst, true );
	if( k !== false ){
		Modal._inst.splice( k, 1 );
	}
	this._unbindEvents();
	if( Modal.resizeOnClose ){
		var event;
		if (document.createEvent) {
			event = document.createEvent("HTMLEvents");
			event.initEvent("resize", true, true);
		} else {
			event = document.createEventObject();
			event.eventType = "resize";
		}
		event.eventName = "resize";
		if (document.createEvent) {
			document.body.dispatchEvent(event);
		} else {
			document.body.fireEvent("on" + event.eventType, event);
		}
	}
	this.overlay.remove();
	this.node.remove();
	this.onClose();
	return true;
};

Modal.prototype.setTitle = function(title){
	this.title = title;
	this.titleTextNode.nodeValue = title;
};

Modal.prototype._overlayOnclick = function(){
	if( !this.overlayClose ){
		return;
	}
	if( this.onBeforeClose() === false ){
		return;
	}
	this.close();
};

Modal.hideBodyScroll = function(){
	var outermost = document.getElementById('outermost');
	var bw = document.body.offsetWidth;
	document.body.style.overflowY = 'hidden';

	if ( outermost ) {
		outermost.style.marginRight = document.body.offsetWidth - bw + 'px';
	}
};

Modal.showBodyScroll = function(){
	var outermost = document.getElementById('outermost');
	document.body.style.overflowY = '';
	if ( outermost ) {
		outermost.style.marginRight = '';
	}
};

Modal._keyDown = Opa.closure( Modal, function(e){
	if( e.keyCode != 27 ){
		return;
	}
	var m = this._inst[ this._inst.length - 1 ];
	if( !m.overlayClose ){
		return;
	}
	if( m.onBeforeClose() === false ){
		return;
	}
	m.close();
} );

Modal._bindEventsGlobal = function(){
	$(document).keydown( this._keyDown );
};

Modal._unbindEventsGlobal = function(){
	$(document).unbind( 'keydown', this._keyDown );
};

var InfoBox = {

	zIndex:1000,
	retVal:false,
	closeParent: true,
	_:{},
	/** @type {Modal} */
	_i:null,

	close:function(){
		if (!this.closeParent) {
			return;
		}
		if(!this.opened && window.parent && window.parent !== window ){
			try{
				parent.InfoBox.close();
			} catch(e){
			}
			return;
		}
		Modal.closeLast();
	},

	_init:function(par){
		par = par || {};
		this.onClose = par.onClose || null;
		par.onClose = Opa.closure( this, this._onClose );
		this.retVal = false;
		this.closeParent = typeof par.closeParent !== 'undefined' ? par.closeParent : true;
		InfoBox.close();
		InfoBox._i = new Modal(par);
		InfoBox._.node = InfoBox._i.node;
		InfoBox.node = InfoBox._i.node;
		InfoBox.content = InfoBox._i.content;
		InfoBox.content.id = 'infobox3_box_content';
	},

	/**
	 * @returns {*}
	 * @private
	 */
	_onClose:function(){
		this.opened = false;
		this._i = null;
		if( this.onClose ){
			return this.onClose();
		}
	},

	open:function( href, par ){
		this._init(par);
		InfoBox._i.open(href);
		return false;
	},

	html:function( html, par ){
		this._init(par);
		InfoBox._i.html(html);
		return false;
	},

	els:function( els, par ){
		this._init(par);
		InfoBox._i.els(els);
		return false;
	},

	iframe:function( href, par ){
		this._init(par);
		InfoBox._i.iframe(href);
		return false;
	},

	img:function( src, par ){
		this._init(par);
		InfoBox._i.img(src);
		return false;
	},

	setTitle:function(v){
		if( this._i ){
			this._i.setTitle(v);
		}
	},

	resizeTo:function( w, h ){
		if( !this._i ){
			return;
		}
		this._i.width = w;
		if(h){
			this._i.height = h;
		}
		this._i._resize();
	}
};
