var Opa = {
	modal:  $('#modalContent'),
	modalClass: '.load_modal',
	modalDialog: '.modal-dialog',
	modalContent: $('#modalCtn'),
	baseUrl: (window.location.origin) ? window.location.origin : window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: ''),
	csrfToken: $('meta[name="_token"]').attr('content'),
	ajaxMsg: $('#ajaxMsg'),
	lang: document.documentElement.lang,
	spItems: null,
	spotlightStep: 0,
	timeout: null,
	signinWin: null,

	loginpopup: function(url) {
		var left = (screen.width/2)-(700/2);
		var top = (screen.height/2)-(400/2);
		Opa.signinWin = window.open(Opa.baseUrl+url, "SignIn", "width=700,height=400,toolbar=0,scrollbars=0,status=0,resizable=0,location=0,menuBar=0,top="+top+",left="+left);
		Opa.signinWin.focus();
		window.setTimeout(function(){Opa.checkLoginStatus()}, 1000);
		return false;
	},

	checkLoginStatus:function() {
		try {
			if (Opa.signinWin.location.pathname && Opa.signinWin.location.pathname != 'blank'){
				var path = Opa.signinWin.location.pathname;
				window.location.replace(Opa.baseUrl+path);
				Opa.signinWin.close();
			}

			window.setTimeout(function(){Opa.checkLoginStatus()}, 500);
		} catch(err) {

			window.setTimeout(function(){Opa.checkLoginStatus()}, 500);
		}
	},

	showLogin: function(){
		$.get( Opa.baseUrl + '/login', function( data ) {
			Opa.modal.modal();
			Opa.modalContent.html(data);
			Opa.hideModal();
		});
	},

	hideModal: function(){
		Opa.modal.on('hidden.bs.modal', function(){
			Opa.modal.removeData('bs.modal');
			Opa.modal.find(Opa.modalClass).html('');
		});
	},

	showMessage: function(message, alert, button, btnMsg, error){

		if (error != undefined && error.status == 401) {
			document.location.href = '/loginform';
			return;
		}

		var container = '';
		Lang.setLocale(Opa.lang);
		btnMsg = (btnMsg !== undefined ? Lang.get(btnMsg): '');
		button = (button !== undefined ? button : false);

		if (Opa.modal.hasClass('in')){
			container = $('#modalMsg');
			if (!container.length) {
				container = mkE({
					tag: 'div',
					id: 'modalMsg',
					className: 'alert alert-'+alert,
				});
				if (!button) {
					Opa.modal.find('.modal-header')[0].appendChild(container);
				}
				container = $('#modalMsg');
				(button) ? button.className="grayDisabled" : '';
			}
		} else {
			container = Opa.ajaxMsg;
			(button) ? button.className="whiteDisabled" : '';
		}
		(button) ? button.innerHTML = btnMsg : '';
		container.removeClass('alert-danger alert-success');
		if (!button) {
			container.addClass('alert-'+alert).html(message).show().delay(2000).fadeOut();
		}
	},

	isPlural: function (number) {
		var lastTwo = number.toString().slice(-2);

		if ((lastTwo.length == 1 && parseInt(lastTwo.slice(-1)) == 1)
			|| (lastTwo.length == 2 && parseInt(lastTwo.slice(-1)) == 1 && parseInt(lastTwo) > 11)) {
			// singular
			return 0;
		}

		// plural
		return 1;
	},

	console: {
		info: function () {
			if (typeof console == 'object' && typeof console.info == 'function') {
				try {
					console.info.apply(console, arguments);
				} catch (e) {
				}
			}
		},
		error: function () {
			if (typeof console == 'object' && typeof console.error == 'function') {
				console.error.apply(console, arguments);
			}
		},
		warn: function () {
			if (typeof console == 'object' && typeof console.warn == 'function') {
				console.warn.apply(console, arguments);
			}
		},
		debug: function () {
			if (typeof console == 'object' && typeof console.debug == 'function') {
				console.debug.apply(console, arguments);
			}
		},
		log: function () {
			if (typeof console == 'object' && typeof console.log == 'function') {
				console.log.apply(console, arguments);
			}
		}
	},

	closure:function( $this, fn ){
		var args = [];
		for( var i = 2; i < arguments.length; ++ i ){
			args.push( arguments[i] );
		}
		return function(){
			$this = $this || this;
			var a = [];
			for( var i = 0; i < arguments.length; ++ i ){
				a.push( arguments[i] );
			}
			return fn.apply( $this, args.concat(a) );
		};
	},

	rq: function( url, element_id, par ){
		if( typeof par != 'object' ){
			par = {};
		}
		if( typeof par.hash == 'undefined' ){
			par.hash = true;
		}
		if( typeof element_id == 'object' ){
			var node = element_id;
		} else {
			var node = document.getElementById( element_id );
		}
		var position, overlay;
		if( node ){
			if( par.overlay ){
				var loadingPar = par.loadingText ? { loadingText: par.loadingText } : {};
				if( typeof par.overlay == 'string' && $( par.overlay, node )[0] ){
					Opa.loadingOverlay( $( par.overlay, node )[0], loadingPar );
				} else {
					Opa.loadingOverlay( node, loadingPar );
				}
			} else {
				if( ! par.withoutLoading ){
					node.innerHTML = '<div class="rqLoading"></div>';
				}
			}
		}
		var _tmpNode = mkE( {
			tag:'div',
			style:{
				display:'none'
			}
		} ).append( document.body );
		var inBody = $(node).parents('body').length > 0;
		var success = function(html){
			if( ! node ){
				Opa.console.error( 'rq - no element' );
				return false;
			}
			if( position ){
				node.style.position = position;
			}

			if( par.replace ){
				if( ! node.parentNode ){
					return;
				}
				var newNode = mkE( {
					tag:node.parentNode.tagName,
					prop:{
						innerHTML:html
					}
				} );
				var nodeChilds = newNode.childNodes;
				var nodeChildsArr = [];
				for( var i = 0; i < nodeChilds.length; i ++ ){
					nodeChildsArr.push( nodeChilds[ i ] );
				}
				for( var k in nodeChildsArr ){
					Opa.insertBefore( nodeChildsArr[ k ], node );
				}
				removeNode(node);
			} else if(par.append){
				$(node).append(html);
			} else {
				if(inBody){
					node.innerHTML = html;
				} else {
					_tmpNode.innerHTML = html;
				}
			}

			if(!par.append){
				evalScriptHTML(html);
				if( !par.replace && !inBody ){
					clearNode(node);
					while( _tmpNode.firstChild ){
						node.appendChild( _tmpNode.firstChild );
					}
				}
			}
			_tmpNode.remove();
			if( par.onload ){
				par.onload(html);
			}
		};
		var get2 = new Get(url);

		$.ajax( {
			url:get2.toUrl(),
			success:success,
			error:function( xhr ){
				if( xhr.status == 403 ){
					var get = new Get();
					for( var k in get._ ){
						get2.add( k, get._[k] );
					}
					document.location.href = '?' + get2.str();
					return;
				}

				if (xhr.status == 401) {
					document.location.href = '/loginform';
					return;
				}
			}
		} );
		return false;
	},

	loadingOverlay:function( node, par ){
		par = par || {};
		if( typeof node == 'string' ){
			node = document.getElementById( node );
		}
		if( ! node ){
			Opa.console.error( 'Opa.loadOverlay( node ) - not element' );
			return false;
		}
		if( node._drOverlay ){
			node._drOverlay.remove();
		}
		if( getStyle( node, 'position' ) == 'static' ){
			node.style.position = 'relative';
		}
		var overlay = node._drOverlay = mkE( {
			tag:'div',
			className:'rqOverlay',
			style:{
				height:'auto',
				width:'auto',
				bottom:'0px',
				right:'0px'
			}
		} ).append(node);
		if( par.delay ){
			addClassName( overlay, 'delay' );
		}
		setTimeout( function(){
			addClassName( overlay, 'fadeIn' );
		}, 1 );
		if( par.loadingText ){
			setTimeout( Opa.closure(this, function(){
				if( node._drOverlay ){
					mkE( {
						tag:'div',
						className:'rqLoadingText',
						text:par.loadingText
					} ).append(overlay);
				}
			} ), 2000);
		}
		if( par.backgroundPosition ){
			overlay.style.backgroundPosition = par.backgroundPosition;
		} else if( overlay.offsetHeight > 400 ){
			overlay.style.backgroundAttachmentX = 'fixed';
		}
		setTimeout( function(){
			var q = $(overlay);
			if( !q.closest('body')[0] ){
				return;
			}
			q.animate({opacity:0.9}, 6000);
		}, 2000 );
		return overlay;
	},

	removeLoadingOverlay:function( node ){
		if( typeof node == 'string' ){
			node = document.getElementById( node );
		}
		if( ! node ){
			Opa.console.error( 'Opa.removeLoadOverlay( node ) - not element' );
			return false;
		}
		if( node._drOverlay ){
			node._drOverlay.remove();
			return true;
		}
		return false;
	},

	sendMail:function(fid, topic, text, callback) {
		return false;
	},

	initiateBackgroundSlider: function(spotlightItems) {
		Opa.spItems = spotlightItems;

		var node = document.getElementById('spotlightRow');

		var list = mkE({
			tag:'ol',
			className:'carousel-indicators custom-carousel pull-right'
		}).append(node);

		for (var i= 0; i<Opa.spItems.length; i++ ){
			mkE({
				tag: 'li',
				className: '',
				id: 'spItems_'+i,
				prop:{
					onclick: function(){Opa.setSlide(this)}
				}
			}).append(list);
		}

		if (Opa.spItems.length > 1) {
			Opa.spotlightStep = 0;
			document.getElementById('spItems_'+Opa.spotlightStep).className = 'active';
			Opa.timeout = setTimeout("Opa.changeslide()",6000);
		}
	},

	changeslide: function(){
		clearTimeout(Opa.timeout);

		$('#backgroundImage').animate({ opacity: 1 }, 900, function(){
			$('#backgroundImage').animate({ opacity: 0 }, 700,function(){
				document.getElementById('spItems_'+Opa.spotlightStep).className = '';
				if (Opa.spotlightStep+1 < Opa.spItems.length) {
					Opa.spotlightStep++;
				} else {
					Opa.spotlightStep=0;
				}
				document.getElementById('spItems_'+Opa.spotlightStep).className = 'active';

				$('#backgroundImage').css("background-image","url("+Opa.spItems[Opa.spotlightStep].spotlight_image+")");
				document.getElementById("spotlightTitle").innerHTML = Opa.spItems[Opa.spotlightStep].title;
				document.getElementById('spotlightHref').href = '/games/play/'+Opa.spItems[Opa.spotlightStep].id;

				$('#backgroundImage').animate({ opacity: 1 }, 1600,function(){
					Opa.timeout = setTimeout("Opa.changeslide()",4400);
				});
			});
		});
	},

	setSlide: function(step){
		clearTimeout(Opa.timeout);
		document.getElementById('spItems_'+Opa.spotlightStep).className = '';
		var step = parseInt(step.id.replace("spItems_", ""));
		Opa.spotlightStep = step;
		document.getElementById('spItems_'+Opa.spotlightStep).className = 'active';
		$('#backgroundImage').css("background-image","url("+Opa.spItems[Opa.spotlightStep].spotlight_image+")");
		document.getElementById("spotlightTitle").innerHTML = Opa.spItems[Opa.spotlightStep].title;
		document.getElementById('spotlightHref').href = '/games/play/'+Opa.spItems[Opa.spotlightStep].id;
		Opa.timeout = setTimeout("Opa.changeslide()",5000);
	},

	reload:function(noParent){
		var doc;
		if( window.parent && window.parent !== window && noParent !== true ){
			doc = window.parent.document;
		} else {
			doc = document;
		}
		doc.location.reload();
		return false;
	},

	dropDown:function( items, par ){
		items = items || [];
		par = par || {};
		var ul;
		var re = mkE( {
			tag:'div',
			className:'dropdownList',
			els:[
				ul = mkE( {
					tag:'ul',
					className:'hover'
				} )
			]
		} );
		O2O( re, par );
		var r, li;
		for( var k in items ){
			r = items[ k ];
			li = mkE( {
				tag:'li',
				els:[
					mkE( {
						tag:'a',
						prop:{
							_drR:r,
							innerHTML:r.caption,
							href:r.href || 'javascript:',
							onclick:function(){
								Opa.dropdownSelect( this, null );
								if( this._drR.onclick ){
									return this._drR.onclick();
								}
								return false;
							}
						}
					} )
				]
			} );
			delete r.caption;
			delete r.onclick;
			delete r.href;
			O2O( li, r );
			if( r.selected ){
				li.prepend( ul );
			} else {
				li.append( ul );
			}
		}
		return re;
	},

	dropdownSelect:function(item, callback){
		var list = $(item.parentNode.parentNode);
		if(list.hasClass("clickable")){
			list.toggleClass('clicked');
		}
		if(item.parentNode == item.parentNode.parentNode.firstChild){
			return false;
		}
		item.parentNode.parentNode.insertBefore( item.parentNode, item.parentNode.parentNode.firstChild);
		if(callback == null){
			return true;
		} else {
			callback();
			return false;
		}
	},

	stopPropagation:function( e ){
		e = e || window.event;
		if ( !e ) {
			return e;
		}
		if( e.stopPropagation ){
			e.stopPropagation();
		}
		e.cancelBubble = true;
		return e;
	},

	submitButton:function(par){
		var type = par.type || 'submit';
		var color = par.color || 'blue';
		var onclick = par.onclick || '';
		var caption = par.caption || '';
		var disabled = par.disabled || '';
		var className = par.className || '';
		var sbtn = mkE({
			tag: 'button',
			className: 'btn btn-primary'+ ' '+className,
			text: caption,
			attr:{type:type},
			prop:{onclick: onclick}
			});
		disabled ? sbtn.disabled = true : '';
		return sbtn;
	},

	button:function(par){
		var type = par.type || 'button';
		var onclick = par.onclick || '';
		var caption = par.caption || '';
		var className = par.className || '';
		return mkE({
			tag: 'button',
			className: 'btn btn-default'+' '+className,
			text: caption,
			attr:{type:type},
			prop:{onclick: onclick}
		});
	},

	radio: function(par){
		var icon = par.icon || '';
		var name = par.name || '';
		var value = par.value || '';
		var onclick = par.onclick || '';
		var checked = par.checked || false;

		var input = '';

		var chk = mkE({
			tag : 'div',
			className: 'radio',
			els: [{
				tag: 'label',
				els:[
					input = mkE({
						tag: 'input',
						name: name,
						id: name,
						attr:{type:'radio', value:value},
						prop:{onclick:onclick}
					}),
					icon
				]
			}],
		});

		(checked) ? input.checked = true : '';

		return chk;
	},

	radioInput: function(par){
		var caption = par.caption || '';
		var name = par.name || '';
		var value = par.value || '';
		var onclick = par.onclick || '';
		return mkE({
			tag: 'input',
			name: name,
			id: name,
			attr:{type:'radio', value:value},
			prop:{onclick:onclick}
		});
	},

	box:function( type, t1, t2 ){
		var re = mkE( {
			tag:'div',
			className:'alert alert-' + type
		} );
		if( !empty(t2)){
			mkE( {
				tag:'strong',
				prop:{
					innerHTML:t1
				}
			} ).append(re);
			mkE( {
				tag:'span',
				prop:{
					innerHTML:' ' + t2
				}
			} ).append(re);
		} else {
			var p1 =  {
				tag:'span'
			};
			if( typeof t1 == 'string' ){
				p1.prop = {
					innerHTML:t1
				};
			} else {
				p1.els = t1;
			}
			mkE(p1).append(re);
		}
		return re;
	},

	info:function( t1, t2 ){
		return this.box( 'info', t1, t2 );
	},

	error:function( t1, t2 ){
		return this.box( 'danger', t1, t2 );
	},

	success:function( t1, t2 ){
		return this.box( 'success', t1, t2 );
	},

	hrText:function( text ){
		return mkE( {
			tag: 'table',
			className: 'hrText',
			els:[
				{
					tag: 'tr',
					els:[
						{
							tag: 'td',
							attr: {
								width:'49%'
							},
							els:[
								{ tag:'hr' }
							]
						},
						{
							tag: 'td',
							className: 'hrTextC',
							prop: {
								innerHTML: text
							}
						},
						{
							tag:'td',
							attr: {
								width:'49%'
							},
							els:[
								{ tag:'hr' }
							]
						}
					]
				}
			]
		} );
	},

	event:function(e){
		e = e || window.event;
		if(!e){
			return e;
		}
		if( typeof e.layerX == 'undefined' ){
			e.layerX = e.offsetX;
			e.layerY = e.offsetY;
		}
		if( typeof e.target == 'undefined' ){
			e.target = e.srcElement;
		}
		return e;
	},

	inIframe: function () {
		try {
			return window.self !== window.top;
		} catch (e) {
			return true;
		}
	},

	resizeWindow: function(){
		var div = document.getElementById('footerContainer');
		var rect = div.getBoundingClientRect ();
		var height = rect.bottom;
		var data = {
			height: height+150,
			action: 'setheight'
		};
		parent.postMessage(data, '*');
	},

	iframeAllowed: function(){
		var frameName =  parent.window.frames[window.name].name;
		var url = (window.location != window.parent.location) ? document.referrer : document.location;
		var baseUrl = Opa.baseUrl+'/';
		if (baseUrl.indexOf(url) == -1) {
			url = encodeURIComponent(url.replace(/&amp;/g, "&"));
			$.ajax( {
				url: Opa.baseUrl + '/allowedNames/'+encodeURIComponent(frameName)+'?url='+url,
				cache: false,
				success: function( data ) {
					if (data.status != 'success') {
						window.location.href = '/error401';
					}
					if (data.firstReq === true) {
						window.location.href= '/';
					}

				}
			}
			).fail(function() {
				window.location.href = '/error401';
			});
		} else {
			return true;
		}
	}
};

mkE.Base = function(){
};

mkE.Base.prototype = {

	append:function(parent){
		this.node.append(parent);
		return this;
	},

	remove:function(){
		this.node.remove();
		return this;
	},

	replace:function(node){
		Opa.insertBefore( this.node, node );
		removeNode(node);
		return this;
	}

};

Opa.Footer = function(par){
	par = par || {};
	this.node = mkE( {
		tag:'div',
		className:'formFooter',
		els:par.els
	} );
	if( par.className ){
		addClassName( this.node, par.className );
	}
};

Opa.Footer.prototype = new mkE.Base;

Opa.Footer.prototype.hide = function(){
	this.node.style.display = 'none';
	return this;
};

Opa.Footer.prototype.show = function(){
	this.node.style.display = '';
};

Opa.Footer.prototype.appendChild = function(child){
	child.append( this.node );
};
