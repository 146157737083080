/**
 * Copy of Get lib.
 * @param url
 * @constructor
 */
var Get = function( url ){
	this.__ = '';
	switch( typeof url ){
		case 'object':
			this._ = {};
			O2O( this._, url );
			return;
		case 'undefined':
			url = document.location.pathname;
			if( document.location.search ){
				url += document.location.search;
			}
			if( document.location.hash ){
				url += document.location.hash;
			}
			break;
	}
	this._ = {};
	if( url ){
		var a = url.split('#')[0].split( '?' );
		this.__ = a[0];
		if( a[ 1 ] ){
			a = a[ 1 ].split( '&' );
			var b;
			for( var i = 0; i < a.length; i ++ ){
				b = a[ i ].split( '=' );
				this._[ decodeURIComponent( b[0] ).replace( /\+/g, ' ' ) ] = ( b[1] ? decodeURIComponent( b[1] ).replace( /\+/g, ' ' ) : '' );
			}
		}
	}
};
Get.prototype = {

	toStr:function(){
		return this.toStrItem( '', '', this._ );
	},

	toStrItem:function( prefix, k, v ){
		prefix = ( prefix ? prefix + '[' + encodeURIComponent( k ) + ']' : encodeURIComponent( k ) );
		if( typeof v == 'object' ){
			var re = [];
			for( var k2 in v ) re.push( this.toStrItem( prefix, k2, v[ k2 ] ) );
			return re.join( '&' );
		} else {
			return prefix + ( v !== '' ? '=' + encodeURIComponent( v ) : '' );
		}
	},

	str:function(){
		return this.toStr();
	},

	toString:function(){
		return this.toStr();
	},

	toUrl:function(){
		var str = this.toStr();
		if(!str){
			return this.__;
		}
		return this.__ + '?' + str;
	},

	add:function( k, v ){
		this._[ k ] = ( v ? v : '' );
		return this;
	},

	remove:function( k ){
		delete this._[ k ];
		return this;
	},

	v:function( k ){
		return ( typeof this._[k] == 'undefined' ? null : this._[k] );
	},

	redirect: function() {
		document.location.href = this.toUrl();
	},

	filterDefaults:function(def){
		for( var k in def ){
			if( isset( this._[k] ) && this._[k] == def[k] ){
				delete this._[k];
			}
		}
		return this;
	}

};
