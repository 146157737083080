var callbackFramePostMessage = false;
var draugiemAppsPostMessage = {};
function processPostMessage(event) {
	var data = event.data;
	callbackFramePostMessage = event.source;

	if (!data || !data['action'] || !data['_id']) {
		return false;
	}

	var iframe = document.getElementById('content_frame');

	if (data['_name'] && draugiemAppsPostMessage[data['_name']]) {
		draugiem_app_id = draugiemAppsPostMessage[data['_name']];
		var frames = document.getElementsByTagName('iframe');
		for (var i = 0; i < frames.length; i++) {
			if (frames[i].name == data['_name']) {
				iframe = frames[i];
			}
		}
	}

	if( event.source != iframe.contentWindow ){
		Opa.console.error("Invalid sender");
		return;
	}

	if (data['action'] == 'setheight') {
		var height = parseInt(data['height']);
		if (height > 200) {
			iframe.setAttribute('height', height + 20);
		}
	}

	if (data['action'] == 'scrolltop') {
		window.scrollTo(0, 0);
	}

	if (data['action'] == 'scrollto') {
		Opa.console.log('Action not supported in opa site.');
	}

	if (data['action'] == 'paymentbox') {
		InfoBox.iframe('/postMessage/paymentBox?id=' + parseInt(data['id']), {
			'width': 450,
			'overlayClose': false,
			'withoutPadding': true,
			'onClose': function () {
				doPostMessageCallback(true, data['_id'])
			}
		});
	}

	if (data['action'] == 'infobox') {
        if (data['url'].indexOf('http://') == 0 || data['url'].indexOf('https://') == 0) {
			var par = {
				'width': parseInt(data['width']),
				'height': parseInt(data['height']),
				'onClose': function () {
					doPostMessageCallback(true, data['_id'])
				}
			};

			if (data['url'].indexOf('/services/iframe.php') >= 0 || data['url'].indexOf('/postMessage/paymentBox') >= 0) {
				data['url'] = data['url'].replace(/https?:\/\/[^\/]+/i, "");
				data['url'] = data['url'].replace("/services/iframe.php", "/postMessage/paymentBox");
				par['withoutPadding'] = true;
				par['overlayClose'] = false;
				par['width'] = 450;
			}
			InfoBox.iframe(data['url'], par);
		}
	}
	if (data['action'] == 'sendmessage') {
		Games.sendMail(
			data['uid'],
			data['topic'],
			data['text'],
			function () {
				doPostMessageCallback(true, data['_id'])
			}
		);
	}
	if (data['action'] == 'selectusers') {
		InfoBox.iframe('/postMessage/selectusers'+'?limit=' + parseInt(data['limit']), {
			'width': 600,
			'onClose': function () {
				doPostMessageCallback(InfoBox.retVal, data['_id'])
			}
		});
	}

	if (data['action'] == 'invite') {
		if (data['extra']) {
			extra = data['extra'];
		} else {
			extra = '';
		}

		if (data['fid']) {
			fid = data['fid'];
		} else {
			fid = '';
		}

		InfoBox.iframe('/postMessage/invite/' + draugiem_app_id + '?text=' + encodeURIComponent(data['text']) + '&extra=' + extra + '&fid=' + fid, {
			'width': 600,
			'height': 250,
			'onClose': function () {
				doPostMessageCallback(InfoBox.retVal, data['_id'])
			}
		});
	}

	if (data['action'] == 'invitation') {
		var text = '';
		if (!empty(data.data['text'])) {
			text = encodeURIComponent(data.data['text']);
		}

		var extra = '';
		if (!empty(data.data['extra'])) {
			extra = data.data['extra'];
		}

		var uids = '';
		if (!empty(data.data['uids'])) {
			uids = data.data['uids'];
		}

		var limit = '';
		if (!empty(data.data['limit'])) {
			limit = '&limit=' + data.data['limit'];
		}

		InfoBox.iframe('/postMessage/invitation/' + draugiem_app_id + '?text=' + encodeURIComponent(data['text']) + '&extra=' + extra + '&fid=' + uids +limit, {
			'width': 600,
			'height': 250,
			'onClose': function () {
				doPostMessageCallback(InfoBox.retVal, data['_id'])
			}
		});
	}

	if (data['action'] == 'pageinvite') {
		Opa.console.log('Action not supported in opa site.');
		doPostMessageCallback(false, data['_id']);
	}

	if (data['action'] == 'authorize') {
		Opa.console.log('Action not supported in opa site.');
	}

	if (data['action'] == 'closeinfobox') {
		InfoBox.close();
	}

	if (data['action'] == 'pagefan') {
		Opa.console.log('Action not supported in opa site.');
	}

	if (data['action'] == 'say') {
		$.ajax({
			url: Opa.baseUrl + '/postMessage/provider',
			method: 'GET',
			dataType: 'json',
		}).done(function (response) {
			sendToFeed(data, response.provider, draugiem_app_id);
		}).fail(function (error) {
			Opa.showMessage('Kļūda. Pārlādējiet lapu, un pārbaudiet, vai esat ielogojies!', 'danger', '', '', error);
		});
	}

	if (data['action'] == 'say2') {
		Opa.console.log('Action not supported in opa site.');
	}

	if (data['action'] == 'gallery') {
		Opa.console.log('Action not supported in opa site.');
	}
	if (data['action'] == 'get_location') {
		doPostMessageCallback(window.top.location.hash, data['_id']);
	}
	if (data['action'] == 'set_location') {
		window.top.location.hash = data['location'];
		doPostMessageCallback(window.top.location.hash, data['_id']);
	}

	if (data['action'] == 'gallerychoose') {
		Opa.console.log('Action not supported in opa site.');
	}

	if (data['action'] == 'settings') {
		Opa.console.log('Action not supported in opa site.');
	}

	if (data['action'] == 'selectfriends') {
		InfoBox.iframe('/postMessage/selectfriends/'+draugiem_app_id+'?maxlength=' + data['maxlength'], {
			'width': 600,
			'onClose': function () {
				doPostMessageCallback(InfoBox.retVal, data['_id'])
			}
		});
	}

	if (data['action'] == 'getPageInfo') {
		(function () {
			var viewportOffset = iframe.getBoundingClientRect();
			var scrollTop = 0 - viewportOffset.top;

			if (scrollTop < 0) {
				scrollTop = 0;
			}

			var info = {
				scrollLeft: (typeof window.pageXOffset !== 'undefined') ? window.pageXOffset
					: (document.documentElement || document.body.parentNode || document.body).scrollLeft,
				scrollTop: (typeof window.pageYOffset !== 'undefined') ? window.pageYOffset
					: (document.documentElement || document.body.parentNode || document.body).scrollTop,
				offsetTop: scrollTop,
				offsetLeft: viewportOffset.left
			};
			doPostMessageCallback(info, data['_id'])
		})();
	}
};

function sendToFeed(data, provider, appId){
	if (provider == 'draugiem') {
		var href = 'https://www.draugiem.lv/say/ext/add.php?title=' + encodeURIComponent(data['title']) +
			'&link=' + encodeURIComponent(Opa.baseUrl+'/games/view/'+appId) +
			( data['titlePrefix'] ? '&titlePrefix=' + encodeURIComponent(data['titlePrefix']) : '' ) +
			( data['text'] ? '&text=' + encodeURIComponent(data['text']) : '' ) +
			( data['picUrl'] ? '&picUrl=' + encodeURIComponent(data['picUrl']) : '' );
		InfoBox.iframe(href, {
			'width': 420,
			'height' : 300,
			withoutPadding: true,
			'onClose': function () {
				doPostMessageCallback(true, data['_id'])
			}
		});
	}

	if (provider == 'facebook') {
		postToFbFeed(data['title'], data['text'], Opa.baseUrl+'/games/view/'+appId, data['picUrl'], function(){doPostMessageCallback(true, data['_id'])});
	}
}

function doPostMessageCallback(data, id) {
	var request = {
		data: data,
		_id: id
	};
	callbackFramePostMessage.postMessage(request, '*');
};

if (window.addEventListener) {
	window.addEventListener('message', processPostMessage, false);
} else {
	window.attachEvent('onmessage', processPostMessage);
};